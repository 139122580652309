import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_gori/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_gori/layout/core'
import {MasterInit} from '../_gori/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-toastify'
import CookieBanner from './modules/common/modals/CookieModal'
import {isFeatureEnabled} from '../_gori/helpers'
import {FEATURES} from '../_gori/constants/_features'

const App = () => {
  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              {isFeatureEnabled(FEATURES.SUPPORT_PAGE) && <CookieBanner />}
              <ToastContainer position='top-right' autoClose={30000} />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}

export {App}
