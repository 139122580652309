import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import OrderService from '../../core/_requests'

type Props = {
  show: boolean
  dataModal: any
  handleClose: () => void
  handleActionSuccess: (key, value) => void
}
const DeleteTagModal: React.FC<Props> = ({
  show,
  dataModal = {},
  handleClose,
  handleActionSuccess,
}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleCloseModal = () => {
    handleClose()
  }

  const handleDeleteTag = async () => {
    let payload = {
      tag_ids: [dataModal.id],
    }
    setIsLoadingForm(true)
    try {
      const {message} = await OrderService.deleteTag(payload, {cancelToken: newCancelToken()})
      if (message) {
        toast.success(intl.formatMessage({id: message}))
        handleCloseModal()
        handleActionSuccess('delete', dataModal.id)
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setIsLoadingForm(false)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_delete_tag'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto mw-800px'
        show={show}
        backdrop='static'
        onHide={() => {
          handleCloseModal()
        }}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <div>{intl.formatMessage({id: 'DELETE_TAG'})}</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center my-4'>
            <img
              className='d-block mx-auto my-5'
              src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')}
              alt='img'
            />
            <p
              className='fs-4'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {id: 'DELETE_TAG_INPUT'},
                  {input: `<strong>${dataModal.name}</strong>`}
                ),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={handleDeleteTag}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {DeleteTagModal}
