import React, {useCallback, useMemo, useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import {ActivityLog} from './dashboard/ActivityLog'
import {PostageHistory} from './dashboard/PostageHistory'
import {PostagePaid} from './dashboard/PostagePaid'
import {ShipmentMap} from './dashboard/ShipmentMap'
import {Wallet} from './dashboard/Wallet'
import {HazmatModal} from '../modals/HazmatModal'
import {useStatesGlobal} from '../../../../_gori/helpers/components/StatesGlobalProvider'
import {useAuth} from '../../auth'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import {isFeatureEnabled} from '../../../../_gori/helpers'
import {FEATURES} from '../../../../_gori/constants/_features'

const DashboardPage: React.FC = () => {
  const {currentUser} = useAuth()
  const {routes} = UseRoutesPermission()
  const {statesGlobal, setHazmat} = useStatesGlobal()
  const [loadingFirst, setLoadingFirst] = useState({
    wallet: isFeatureEnabled(FEATURES.METER),
    postagePaid: isFeatureEnabled(FEATURES.METER),
    postageHistory: true,
    activityLog: true,
    shipmentsMap: true,
  })

  const handleSetLoading = useCallback((key) => {
    setLoadingFirst((prev) => ({...prev, [key]: false}))
  }, [])

  const loading = useMemo(() => {
    return !Object.values(loadingFirst).every((value) => !value)
  }, [loadingFirst])

  return (
    <>
      {!routes.SYSTEM_ADMIN.hasPermission && statesGlobal.hazmat && currentUser && (
        <HazmatModal show={statesGlobal.hazmat} handleClose={() => setHazmat(false)} />
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='dashboard'>
          <div className='row gy-5 g-xl-7 mb-7'>
            {isFeatureEnabled(FEATURES.METER) ? (
              <>
                <div className='col-xl-4'>
                  <Wallet loading={loading} onLoading={handleSetLoading} />
                </div>
                <div className='col-xl-8'>
                  <div className='row gy-5 g-xl-7'>
                    <div className='col-xl-5'>
                      <PostagePaid loading={loading} onLoading={handleSetLoading} />
                    </div>
                    <div className='col-xl-7'>
                      <PostageHistory loading={loading} onLoading={handleSetLoading} />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='col-xl-8'>
                <div className='row gy-5 g-xl-7'>
                  <div className='col-xl-7'>
                    <PostageHistory loading={loading} onLoading={handleSetLoading} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='row gy-5 g-xl-7 mb-7'>
            <div className='col'>
              <ShipmentMap loading={loading} onLoading={handleSetLoading} />
            </div>
          </div>
          <div className='row gy-5 g-xl-7 mb-7'>
            <div className='col-xl-4 m-xl-0'>
              <ActivityLog loading={loading} onLoading={handleSetLoading} />
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {DashboardPage}
