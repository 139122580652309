import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {cloneDeep, isEmpty} from 'lodash'
import {FC, useMemo, useRef} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'

type Props = {
  className?: string
  labelClassName?: string | null
  label?: string | null
  placeholder?: string
  required?: boolean
  formik: FormikProps<any>
  name?: string
  nameParent?: string
  maxEmailNumber?: number
  configAppend?: {
    name: string
    position: 'right' | 'left'
    classInput?: string
    classAppend?: string
    hasEvent?: boolean
  }
}

const InputEmailFormik: FC<Props> = ({
  className,
  labelClassName = '',
  label = null,
  placeholder = '',
  required = false,
  formik,
  name = 'email',
  nameParent = 'emails',
  maxEmailNumber = 3,
  configAppend,
}) => {
  let fieldProps = formik.getFieldProps(name)
  let formikValues = getIn(formik.values, name)
  let formikValuesParent = getIn(formik.values, nameParent) || []
  let formikErrors = getIn(formik.errors, name)
  let formikErrorsParent = getIn(formik.errors, nameParent)
  let formikTouched = getIn(formik.touched, name)

  const intl = useIntl()
  const emailRef = useRef<any>(null)

  const handleEmailArrayUpdate = (value) => {
    formik.setValues({
      ...formik.values,
      [nameParent]: [...formik.values[nameParent], value],
      [name]: '',
    })
    formik.setErrors({})
  }

  const handleEmailArrayDelete = (keyDelete, index) => {
    const arrDelete = cloneDeep(formik.values?.[keyDelete])
    arrDelete.splice(index, 1)
    formik.setFieldValue(keyDelete, arrDelete)
  }

  const handleEmailClick = (item) => {
    const arrNew = cloneDeep(formik.values?.[nameParent]).filter((email) => email !== item)
    formik.setValues({...formik.values, [nameParent]: arrNew, [name]: item})
    emailRef.current.focus()
  }

  const handleChangeEmail = (e) => {
    const value = e.target.value.trim()
    const errorEmail = getIn(formik.errors, name)
    if (
      !errorEmail &&
      ['Enter', 'Space', 'Comma'].includes(e.code) &&
      value &&
      formikValuesParent.length < maxEmailNumber
    ) {
      handleEmailArrayUpdate(value)
      e.preventDefault()
    }
  }

  const handleBlurInput = (e) => {
    const value = e.target.value.trim()
    formik.setFieldTouched(name, true)
    if (!formikErrors && value && formikValuesParent.length < maxEmailNumber) {
      handleEmailArrayUpdate(value)
    }
  }

  const checkLimitedEmail = useMemo(() => {
    return formikValues && formikValuesParent.length === maxEmailNumber
  }, [formikValues, formikValuesParent.length, maxEmailNumber])

  const renderEmailErrors = () => {
    if (checkLimitedEmail) {
      return (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block text-danger'>
            {intl.formatMessage(
              {id: 'ONLY_UP_TO_INPUT_EMAIL_ADDRESSES_ARE_ALLOWED'},
              {input: maxEmailNumber}
            )}
          </div>
        </div>
      )
    }
    if (!formikTouched) return null

    return (
      <div className='fv-plugins-message-container'>
        <div className='fv-help-block text-danger'>
          {formik.errors[nameParent] && !formik.values[name] && (
            <span role='alert'>{formik.errors[nameParent]}</span>
          )}
          {formik.errors[name] && <span role='alert'>{formik.errors[name]}</span>}
        </div>
      </div>
    )
  }

  const checkValidate = useMemo(() => {
    const isInvalid =
      (formikTouched && (formikErrors || (formikErrorsParent && !formikValues))) ||
      checkLimitedEmail
    const isValid = formikTouched && formikValues && !formikErrors && !checkLimitedEmail
    return {isInvalid, isValid}
  }, [checkLimitedEmail, formikErrors, formikErrorsParent, formikTouched, formikValues])

  const handleClickAppend = () => {
    if (!configAppend?.hasEvent && checkValidate.isInvalid) return
    if (!formikErrors && formikValues && formikValuesParent.length < maxEmailNumber) {
      handleEmailArrayUpdate(formikValues)
    }
  }

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={className}>
        <div className='d-flex flex-column'>
          <div className='input-custom'>
            <input
              ref={emailRef}
              type='text'
              placeholder={placeholder}
              autoComplete='off'
              className={clsx('form-control input-custom__input', {
                'is-invalid': checkValidate.isInvalid,
                'is-valid': checkValidate.isValid,
                [configAppend?.classInput ?? '']: !!configAppend?.classInput,
              })}
              {...fieldProps}
              onKeyDown={(e) => handleChangeEmail(e)}
              onBlur={(e) => handleBlurInput(e)}
              onFocus={(e) => {
                if (e.target.value === '0') {
                  let eventField = {target: {name: fieldProps.name, value: ''}}
                  formik.handleChange(eventField)
                }
              }}
            />
            <span
              className={clsx(
                `input-custom__append input-custom__append--${configAppend?.position}`,
                {
                  'cursor-pointer text-primary': checkValidate.isValid,
                  'cursor-no-drop': configAppend?.hasEvent && checkValidate.isInvalid,
                  [configAppend?.classAppend ?? '']: !!configAppend?.classAppend,
                }
              )}
              onClick={handleClickAppend}
            >
              {configAppend?.name}
            </span>
          </div>
          {renderEmailErrors()}
        </div>
        <div className='d-flex flex-wrap'>
          {!isEmpty(formikValuesParent) &&
            formikValuesParent.map((item, key) => (
              <div
                key={key}
                className={
                  'my-1 p-1 fw-bold d-flex justify-content-between align-items-center bg-light-primary border me-2 rounded-2 mw-110'
                }
              >
                <div
                  className='cursor-pointer text-hover-primary fs-8 mw-250px text-truncate'
                  onClick={() => handleEmailClick(item)}
                >
                  {item}
                </div>
                <span
                  className='ms-2 cursor-pointer'
                  onClick={() => handleEmailArrayDelete(nameParent, key)}
                >
                  <KTSVG
                    path='/media/gori/orders/delete.svg'
                    className='m-0 text-hover-danger'
                    svgClassName='mh-8px'
                  />
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export {InputEmailFormik}
