import {CSSTransition} from 'react-transition-group'
import {useEffect} from 'react'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {StorageHelpers} from '../../../_gori/helpers'

export function EbayVerifyPage() {
  let slug = StorageHelpers.getItemCookies('slug')
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    StorageHelpers.setItemCookies('ebay-code', code, {
      path: '/',
      domain: BASE_DOMAIN?.split(':')[0],
    })

    const protocol = document.location.protocol
    const url = protocol + '//' + slug + '.' + BASE_DOMAIN + '/settings/connections/ebay'
    if (slug) {
      document.location.replace(url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}
