import {useFormik} from 'formik'
import {cloneDeep, isEmpty, omit, size, unionBy} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseRoutesPermission from '../../../../_gori/hooks/UseRoutesPermission'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  ConfirmActionSwal,
  InputTextArea,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {useAuth} from '../../auth'
import {SkeletonClaimsForm, UploadMultipleFile, ViewUploadedOldFile} from '../../claims'
import {ClaimsConfig, OPTION_CLAIMS_REASON, OPTION_ITEM_TYPE} from '../core/_const'
import ClaimsService from '../core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
  claimId: number | undefined
  reloadTable: any
}

const EditClaimsModal: React.FC<Props> = ({show, handleClose, claimId, reloadTable}) => {
  const intl = useIntl()
  let key = useRef<number>(0)
  const {newCancelToken, isCancel} = useCancelToken()
  const {loadingSwitch} = useAuth()
  const {stringYup, arrayRequiredYup, infoYup, numberYup, claimYup} = UseYupValidate()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [dataFormInit, setDataFormInit] = useState<any>({})
  const [dataForm, setDataForm] = useState<any>({})
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const [loadingFirst, setLoadingFirst] = useState<boolean>(true)
  const {routes} = UseRoutesPermission()
  const checkPermissionView = routes.CLAIMS_UPDATE.hasPermission
    ? false
    : routes.CLAIMS_VIEW.hasPermission

  const initValidateSchema = useMemo(() => {
    return {
      tracking_number: stringYup(50, 'TRACKING_NUMBER'),
      claim_reason: stringYup(50, 'CLAIM_REASON'),
      items: Yup.object().shape({
        [key.current]: Yup.object().shape({
          claim_item_name: stringYup(70, 'ITEM_NAME'),
          claim_item_type: stringYup(50, 'ITEM_TYPE'),
          claim_item_specific_item_type: claimYup.specificItemType,
          claim_item_price: numberYup.unitPrice(),
          claim_item_qty: numberYup.quantity(),
          claim_item_desc: stringYup(2000, 'ITEM_DESCRIPTION'),
          photos_init: Yup.array(),
          photos_new: Yup.array().when('photos_init', {
            is: (val: string) => val?.length <= 0,
            then: arrayRequiredYup('PHOTO_OF_ITEM'),
          }),
        }),
      }),
      claim_init_invoice: Yup.array(),
      claim_invoice_new: Yup.array().when('claim_init_invoice', {
        is: (val: string) => val?.length <= 0,
        then: arrayRequiredYup('PROOF_OF_VALUE_INVOICE'),
      }),
      claim_recipient_phone: infoYup.phone("RECIPIENT'S_PHONE"),
      claim_recipient_email: infoYup.email("RECIPIENT'S_EMAIL", false),
      claim_is_replace_package: Yup.boolean(),
      tracking_number_replace: claimYup.replacementTrackingNumber,
    }
  }, [
    arrayRequiredYup,
    claimYup.replacementTrackingNumber,
    claimYup.specificItemType,
    infoYup,
    numberYup,
    stringYup,
  ])

  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)

  const getClaimsDetail = useCallback(async () => {
    try {
      const config = {cancelToken: newCancelToken()}

      // set init formik
      const {claim} = await ClaimsService.getClaimDetail(claimId?.toString(), config)
      if (claim) {
        const newItems = claim.items.map((item: any, index) => {
          item.show = true
          item.key = index
          return item
        })
        setDataFormInit({...claim, items: newItems})

        // set init validate formik
        const newValidates = claim.items.reduce((validateItems, validateCurrent, index) => {
          validateItems[index] = cloneDeep(initValidateSchema.items.fields[0])
          return validateItems
        }, {})
        setValidateSchema((prev) => ({...prev, items: Yup.object().shape({...newValidates})}))

        // set key of item continue
        key.current = claim.items.length - 1
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoadingFirst(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimId, isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch || !claimId || !show) return

    getClaimsDetail()
  }, [claimId, getClaimsDetail, loadingSwitch, show])

  const items = useMemo(() => {
    return dataFormInit?.items?.reduce((initItems, itemCurrent) => {
      const item = {
        show: itemCurrent?.show || true,
        claim_item_name: itemCurrent?.claim_item_name || '',
        claim_item_specific_item_type: itemCurrent?.claim_item_specific_item_type || '',
        claim_item_type: itemCurrent?.claim_item_type || '',
        claim_item_price: itemCurrent?.claim_item_price || '',
        claim_item_qty: itemCurrent?.claim_item_qty || '',
        claim_item_desc: itemCurrent?.claim_item_desc || '',
        photos_init:
          itemCurrent?.photos.map((photo, index) => ({
            ...photo,
            path: photo.media_path,
            key: index,
          })) || [],
        photos_new: [],
      }
      initItems[itemCurrent.key] = item
      return initItems
    }, {})
  }, [dataFormInit?.items])

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      claim_id: dataFormInit?.claim_id,
      tracking_number: dataFormInit.tracking_number || '',
      claim_reason: dataFormInit.claim_reason || '',
      items: items || {},
      claim_init_invoice:
        dataFormInit?.claim_invoice?.map((photo, index) => ({
          ...photo,
          path: photo.media_path,
          key: index,
        })) || [],
      claim_invoice_new: [],
      claim_is_replace_package: dataFormInit?.claim_is_replace_package === 1 || false,
      claim_is_plan_replace_package: dataFormInit?.claim_is_plan_replace_package === 1 || false,
      tracking_number_replace: dataFormInit?.tracking_number_replace || '',
      claim_recipient_phone: dataFormInit?.claim_recipient_phone || '',
      claim_recipient_email: dataFormInit?.claim_recipient_email || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setDataForm(values)
      setShowConfirmModal(true)
    },
  })

  const onSubmitPhotoOfItem = useCallback(
    (key, values) => {
      formik.setFieldValue(formik.getFieldProps(`items.${key}.photos_new`).name, values)
    },
    [formik]
  )

  const onSubmitProofOfValue = useCallback(
    (values) => {
      formik.setFieldValue(formik.getFieldProps('claim_invoice_new').name, values)
    },
    [formik]
  )

  const handleAddNewItem = () => {
    const newValidate = Yup.object().shape({
      ...validateSchema.items.fields,
      [key.current + 1]: cloneDeep(initValidateSchema.items.fields[key.current]),
    })
    setValidateSchema((prev) => {
      return {
        ...prev,
        items: newValidate,
      }
    })

    const newItem = {
      [key.current + 1]: {
        show: true,
        claim_item_name: '',
        claim_item_specific_item_type: '',
        claim_item_type: '',
        claim_item_price: '',
        claim_item_qty: '',
        claim_item_desc: '',
        photos_init: [],
        photos_new: [],
      },
    }
    formik.setFieldValue(formik.getFieldProps('items')?.name, {
      ...formik.getFieldProps('items')?.value,
      ...newItem,
    })
    key.current += 1
  }

  const deleteItemObj = (obj, keyDelete) => {
    const newObject = Object.entries(obj).reduce((initValue, [keyItem, item]) => {
      if (keyItem !== keyDelete) {
        initValue[keyItem] = item
      }
      return initValue
    }, {})

    return newObject
  }

  const handleDeleteItem = (keyDelete) => {
    const objDelete = deleteItemObj(formik.getFieldProps('items')?.value, keyDelete)
    formik.setFieldValue(formik.getFieldProps('items')?.name, objDelete)

    const objValidate = deleteItemObj(validateSchema.items.fields, keyDelete)
    setValidateSchema((prev) => {
      return {
        ...prev,
        items: Yup.object().shape(objValidate),
      }
    })
    formik.setTouched({}, false)
  }

  const handleSubmitForm = async () => {
    setIsLoadingForm(true)
    try {
      const _dataForm = cloneDeep(dataForm)
      const joinInvoice = unionBy(_dataForm.claim_init_invoice, _dataForm.claim_invoice_new, 'path')
      const items = Object.values(_dataForm.items).map((value: any) => {
        if (value.claim_item_type !== ClaimsConfig.ITEM_TYPE_OTHER) {
          value.claim_item_specific_item_type = ''
        }
        const joinPhotos = unionBy(value.photos_init, value.photos_new, 'path')
        return omit({...value, photos: joinPhotos}, ['photos_init', 'photos_new'])
      })
      _dataForm.claim_invoice = joinInvoice
      _dataForm.items = items

      const res = await ClaimsService.editClaims(
        {
          ...omit(_dataForm, ['claim_init_invoice', 'claim_invoice_new']),
          claim_is_replace_package: _dataForm.claim_is_replace_package ? 1 : 0,
          claim_is_plan_replace_package: _dataForm.claim_is_plan_replace_package ? 1 : 0,
        },
        {cancelToken: newCancelToken()}
      )
      if (res) {
        toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
        handleCloseModal()
        reloadTable()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setIsLoadingForm(false)
    }
  }

  const handleCloseModal = () => {
    handleClose()
    setDataFormInit({})
    setDataForm({})
    setLoadingFirst(true)
    key.current = 0
    formik.resetForm()
  }

  useEffect(() => {
    if (isEmpty(dataForm)) return

    if (!formik.getFieldProps('claim_is_replace_package').value) {
      formik.setFieldValue(formik.getFieldProps('tracking_number_replace').name, '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.getFieldProps('claim_is_replace_package').value])

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <ConfirmActionSwal
        show={showConfirmModal}
        title={intl.formatMessage({id: 'EDIT_A_CLAIM'})}
        message={intl.formatMessage({id: 'ARE_YOU_SURE'})}
        messageCancel={intl.formatMessage({id: 'NO'})}
        handleCallBack={handleSubmitForm}
        handleClose={() => setShowConfirmModal(false)}
      />
      <Modal
        id='gori_modal_edit_claims'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'CLAIM_FORM'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            {loadingFirst || isEmpty(dataFormInit) ? (
              <SkeletonClaimsForm name='edit' />
            ) : (
              <Container>
                {/* Title */}
                <div className='mb-13 text-center'>
                  <h1 className='mb-3'>{intl.formatMessage({id: 'EDIT_A_CLAIM'})}</h1>
                  <div className='text-muted fw-semibold fs-5'>
                    {intl.formatMessage({
                      id: 'TRY_YOUR_BEST_TO_FILL_OUT_THE_INFORMATION_FOR_THE_BEST_RESULT',
                    })}
                  </div>
                </div>
                <div className='row mb-8'>
                  <div className='col-md-6 mb-1'>
                    <div className='d-flex cursor-no-drop'>
                      <InputTextFormik
                        className='col-8 pe-none'
                        labelClassName='col-4 col-form-label'
                        required
                        label={intl.formatMessage({id: 'TRACKING_NUMBER'})}
                        formik={formik}
                        name='tracking_number'
                        bgSolid
                        disabled={checkPermissionView}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='d-flex'>
                      <SelectFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label'
                        label={intl.formatMessage({id: 'CLAIM_REASON'})}
                        placeholder={intl.formatMessage(
                          {id: 'SELECT_A_INPUT'},
                          {input: intl.formatMessage({id: 'CLAIM_REASON'})}
                        )}
                        emptyDefault={false}
                        required
                        options={OPTION_CLAIMS_REASON}
                        name='claim_reason'
                        formik={formik}
                        disabled={checkPermissionView}
                        hasUseIntl={true}
                      />
                    </div>
                  </div>
                </div>
                {/* Items Detail */}
                <div className='mt-15 mb-5'>
                  <h3>{intl.formatMessage({id: 'ITEM_DETAILS'})}</h3>
                  {/* <hr /> */}
                </div>
                {Object.entries(formik.getFieldProps('items')?.value).map(([key, item], index) => {
                  return (
                    <div key={key} className='bg-light px-10 pt-5 pb-2 mb-4 rounded'>
                      <div className='d-flex align-items-center justify-content-between mb-5'>
                        <span className='fs-6 fw-bolder'>
                          {intl.formatMessage({id: 'ITEM'})} {index + 1}
                        </span>
                        <div className='d-flex align-items-center'>
                          {size(formik.getFieldProps('items')?.value) >
                            ClaimsConfig.SHOW_DELETE_ITEM && (
                            <div
                              className='pe-3 text-danger fw-bold cursor-pointer text-decoration-underline'
                              onClick={() => handleDeleteItem(key)}
                            >
                              {intl.formatMessage({id: 'DELETE_ITEM'})}
                            </div>
                          )}
                          <div
                            className='btn btn-sm btn-icon btn-white btn-active-light opacity-75 cursor-pointer rounded-circle'
                            onClick={() =>
                              formik.setFieldValue(
                                formik.getFieldProps(`items.${key}.show`).name,
                                !formik.getFieldProps(`items.${key}.show`).value
                              )
                            }
                          >
                            {formik.getFieldProps(`items.${key}.show`).value ? (
                              <KTSVG path='/media/gori/claims/down.svg' className='svg-icon-2' />
                            ) : (
                              <KTSVG path='/media/gori/claims/left.svg' className='svg-icon-2' />
                            )}
                          </div>
                        </div>
                      </div>
                      {formik.getFieldProps(`items.${key}.show`).value && (
                        <>
                          <div className='row mb-5'>
                            <div className='col-md-6'>
                              <div className='d-flex mb-5'>
                                <InputTextFormik
                                  className='col-8'
                                  labelClassName='col-4 col-form-label'
                                  required
                                  label={intl.formatMessage({id: 'ITEM_NAME'})}
                                  formik={formik}
                                  name={`items.${key}.claim_item_name`}
                                  disabled={checkPermissionView}
                                />
                              </div>
                              <div className='d-flex mb-5'>
                                <InputTextFormik
                                  type={'number'}
                                  min={0}
                                  placeholder='$'
                                  className='col-8'
                                  labelClassName='col-4 col-form-label'
                                  required
                                  label={intl.formatMessage({id: 'UNIT_PRICE'})}
                                  formik={formik}
                                  name={`items.${key}.claim_item_price`}
                                  isCurrency
                                />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='d-flex mb-5'>
                                <SelectFormik
                                  className='col-8'
                                  labelClassName='col-4 col-form-label'
                                  label={intl.formatMessage({id: 'ITEM_TYPE'})}
                                  placeholder={intl.formatMessage(
                                    {id: 'SELECT_A_INPUT'},
                                    {input: intl.formatMessage({id: 'ITEM_TYPE'})}
                                  )}
                                  emptyDefault={false}
                                  required
                                  options={OPTION_ITEM_TYPE}
                                  formik={formik}
                                  name={`items.${key}.claim_item_type`}
                                  disabled={checkPermissionView}
                                  hasUseIntl={true}
                                />
                              </div>
                              {formik.getFieldProps(`items.${key}.claim_item_type`).value ===
                                ClaimsConfig.ITEM_TYPE_OTHER && (
                                <div className='d-flex mb-5'>
                                  <InputTextFormik
                                    className='col-8'
                                    labelClassName='col-4 col-form-label'
                                    required
                                    label={intl.formatMessage({id: 'SPECIFIC_ITEM_TYPE'})}
                                    formik={formik}
                                    name={`items.${key}.claim_item_specific_item_type`}
                                    disabled={checkPermissionView}
                                  />
                                </div>
                              )}

                              <div className='d-flex'>
                                <InputTextFormik
                                  type={'number'}
                                  min={0}
                                  isInteger
                                  className='col-8'
                                  labelClassName='col-4 col-form-label'
                                  required
                                  label={intl.formatMessage({id: 'QUANTITY'})}
                                  formik={formik}
                                  name={`items.${key}.claim_item_qty`}
                                  disabled={checkPermissionView}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='row mb-5'>
                            <InputTextArea
                              className='col-12'
                              labelClassName='col-form-label'
                              label={intl.formatMessage({id: 'ITEM_DESCRIPTION'})}
                              formik={formik}
                              name={`items.${key}.claim_item_desc`}
                              required
                              disabled={checkPermissionView}
                            />
                          </div>

                          <div className='row mb-5'>
                            <UploadMultipleFile
                              label={intl.formatMessage({
                                id:
                                  formik.getFieldProps('claim_reason').value === 'damage'
                                    ? 'PHOTO_OF_DAMAGED_ITEM'
                                    : 'PHOTO_OF_ITEM',
                              })}
                              required
                              onSubmit={(value) => onSubmitPhotoOfItem(key, value)}
                              formik={formik}
                              name={`items.${key}.photos_new`}
                              disabled={checkPermissionView}
                            />
                          </div>
                          <ViewUploadedOldFile
                            formik={formik}
                            nameField={`items.${key}.photos_init`}
                          />
                        </>
                      )}
                    </div>
                  )
                })}
                {size(formik.getFieldProps('items')?.value) < ClaimsConfig.SHOW_ADD_NEW_ITEM && (
                  <div
                    className='btn btn-light d-grid d-flex justify-content-center'
                    onClick={handleAddNewItem}
                  >
                    <KTSVG path={'/media/gori/claims/add-item.svg'} className='svg-icon-3' />
                    {intl.formatMessage({id: 'ADD_NEW_ITEM'})}
                  </div>
                )}
                {/* Purchase Information */}
                <div className='mt-15 mb-5'>
                  <h3>{intl.formatMessage({id: 'PURCHASE_INFORMATION'})}</h3>
                  {/* <hr /> */}
                </div>
                <div className='row mb-10'>
                  <UploadMultipleFile
                    label={intl.formatMessage({id: 'PROOF_OF_VALUE_INVOICE'})}
                    required
                    onSubmit={onSubmitProofOfValue}
                    formik={formik}
                    name={'claim_invoice_new'}
                    disabled={checkPermissionView}
                  />
                </div>
                <ViewUploadedOldFile formik={formik} nameField={'claim_init_invoice'} />

                {/* Replacement Package Information */}
                <div className='mt-15 mb-5'>
                  <h3>{intl.formatMessage({id: 'REPLACEMENT_PACKAGE_INFORMATION'})}</h3>
                </div>
                <div className='d-flex align-items-center mb-5'>
                  <span className='fs-6 fw-semibold text-gray-800 d-block'>
                    {intl.formatMessage({id: 'HAS_A_REPLACEMENT_PACKAGE_BEEN_SHIPPED'})}
                  </span>
                  <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={formik.getFieldProps('claim_is_replace_package').value}
                      value={formik.getFieldProps('claim_is_replace_package').value}
                      onChange={formik.handleChange}
                      name='claim_is_replace_package'
                      disabled={checkPermissionView}
                    />
                  </label>
                </div>
                {formik.getFieldProps('claim_is_replace_package').value ? (
                  <div className='d-flex'>
                    <InputTextFormik
                      className='col-9'
                      labelClassName='col-3 col-form-label'
                      label={intl.formatMessage({id: 'REPLACEMENT_TRACKING_NUMBER'})}
                      required
                      formik={formik}
                      name={'tracking_number_replace'}
                      disabled={checkPermissionView}
                    />
                  </div>
                ) : (
                  <div className='d-flex align-items-center mb-5'>
                    <span className='fs-6 fw-semibold text-gray-800 d-block'>
                      {intl.formatMessage({id: 'DO_YOU_PLAN_ON_SHIPPING_A_RE_PLACEMENT_PACKAGE'})}
                    </span>
                    <label className='form-check form-switch form-check-custom form-check-solid px-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={formik.getFieldProps('claim_is_plan_replace_package').value}
                        value={formik.getFieldProps('claim_is_plan_replace_package').value}
                        onChange={formik.handleChange}
                        name='claim_is_plan_replace_package'
                        disabled={checkPermissionView}
                      />
                    </label>
                  </div>
                )}
                {/* Recipient Contacts */}
                <div className='mt-15 mb-5'>
                  <h3>{intl.formatMessage({id: 'RECIPIENT_CONTACTS'})}</h3>
                  {/* <hr /> */}
                </div>
                <div className='row mb-5'>
                  <div className='col-md-6 mb-1'>
                    <div className='d-flex'>
                      <InputTextFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label'
                        required
                        label={intl.formatMessage({id: "RECIPIENT'S_PHONE"})}
                        formik={formik}
                        name='claim_recipient_phone'
                        disabled={checkPermissionView}
                      />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='d-flex'>
                      <InputTextFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label'
                        label={intl.formatMessage({id: "RECIPIENT'S_EMAIL"})}
                        formik={formik}
                        name='claim_recipient_email'
                        disabled={checkPermissionView}
                      />
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              {routes.CLAIMS_UPDATE.hasPermission && (
                <Button
                  className='btn btn-primary'
                  label={intl.formatMessage({id: 'SAVE'})}
                  loadingText={intl.formatMessage({id: 'SAVE'})}
                  disabled={isLoadingForm}
                  event={formik.submitForm}
                  loading={isLoadingForm}
                />
              )}
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {EditClaimsModal}
