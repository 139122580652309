import React, {useState} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useIntl} from 'react-intl'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {KTSVG, toAbsoluteUrl} from '../../../../../_gori/helpers'
import {ACHModal, CreditCardModal, WireTransferModal} from '../../../onboard'
import {STEPS} from '../../core/_const'
import OnboardService from '../../core/_requests'
import {useAuth} from '../../../auth'
import {ButtonBack} from '../../../../../_gori/partials/widgets'
import {ButtonLabelCreation} from '../../../../../_gori/partials/widgets/form/ButtonLabelCreation'
import {useNavigate} from 'react-router-dom'
import {useLanguage} from '../../../../../_gori/i18n/Metronici18n'
import clsx from 'clsx'

type Props = {
  setActiveStep: any
}
const fundingMethods = [
  {
    label: 'ACH',
    value: 'ach',
    image: '/media/gori/account/ach.png',
  },
  {
    label: 'WIRE_TRANSFER',
    value: 'wire_transfer',
    image: '/media/gori/account/wire.png',
  },
  {
    label: 'CREDIT_DEBIT_CARD',
    value: 'credit_debit_card',
    image: '/media/gori/account/card.png',
  },
  {
    label: 'PAYPAL',
    value: 'paypal',
    image: '/media/gori/account/paypal_no_title.png',
  },
]

const AddFund: React.FC<Props> = ({setActiveStep}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {language} = useLanguage()
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)
  const {newCancelToken} = useCancelToken()
  const {auth} = useAuth()
  const baseDomain = process.env.REACT_APP_DOMAIN
  const DOMAIN_PAYMENT = process.env.REACT_APP_DOMAIN_PAYMENT

  const nextStep = async () => {
    let payload = {
      step: 'add_fund',
      data: {funding_option: selectedMethod},
      funding_option: selectedMethod,
    }
    const res = await OnboardService.store(payload, {cancelToken: newCancelToken()})
    if (res) {
      setActiveStep(STEPS.label_creation)
    }
  }

  const onChangeMethodOnboard = (methodSelect) => {
    if (methodSelect === 'credit_debit_card' || methodSelect === 'paypal') {
      const hostUrl = document.location.host?.split(':')?.[0]
      const domain = baseDomain?.split(':')?.[0]
      const subDomain = hostUrl.split(`.${domain}`).filter((item) => item !== domain)?.[0]
      let urlRedirect = `${DOMAIN_PAYMENT}/auth/verify-token?slug=${subDomain}&language=${language}&method=${methodSelect}&token=${auth?.access_token}`
      document.location.replace(urlRedirect)
    } else {
      setSelectedMethod(methodSelect)
    }
  }

  const backStep = () => {
    setActiveStep(STEPS.ob_general)
  }

  return (
    <>
      {selectedMethod && (
        <>
          <ACHModal
            show={selectedMethod === 'ach'}
            handleCloseModal={() => setSelectedMethod(null)}
            setActiveStep={setActiveStep}
          />
          <WireTransferModal
            show={selectedMethod === 'wire_transfer'}
            handleCloseModal={() => setSelectedMethod(null)}
            nextStep={nextStep}
          />
          <CreditCardModal
            show={selectedMethod === 'credit_debit_card'}
            handleCloseModal={() => setSelectedMethod(null)}
            nextStep={nextStep}
          />
        </>
      )}
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='add-fund text-center fade-appear-done fade-enter-done'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div
              className='add-fund-text'
              dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'ADD_FUND_TEXT'})}}
            ></div>

            <div className='w-75'>
              <div className='row justify-content-center d-flex '>
                {fundingMethods.map((item, index) => {
                  return (
                    <div
                      className={clsx('col-md-3')}
                      key={index}
                      onClick={() => {
                        onChangeMethodOnboard(item.value)
                      }}
                    >
                      <div
                        className={`choose-box shadow ${
                          selectedMethod === item.value ? 'active' : ''
                        }`}
                      >
                        <div>
                          <img
                            alt={intl.formatMessage({id: item.label})}
                            src={toAbsoluteUrl(item.image)}
                            className='h-100px m-1'
                          />
                        </div>
                        <div className='choose-text'>
                          <span> {intl.formatMessage({id: item.label})}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='row justify-content-between'>
                <div className='col-md-3 mt-5'>
                  <ButtonBack
                    event={() => backStep()}
                    className='back onboard-form'
                    label={intl.formatMessage({id: 'BACK'})}
                  />
                </div>
                <div className='col-md-3 skip' onClick={() => setActiveStep(STEPS.label_creation)}>
                  {intl.formatMessage({id: 'SKIP'})}
                  <KTSVG path='/media/gori/arrows/arrow_right.svg' className='svg-icon-3 m-0' />
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 mt-10 save-and-exit'>
            <ButtonLabelCreation
              className='btn btn-light'
              label={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              loadingText={intl.formatMessage({id: 'SAVE_AND_EXIT'})}
              event={() => {
                navigate('/logout')
              }}
            >
              <KTSVG path='/media/gori/orders/delete.svg' className='m-0' svgClassName='mh-10px' />
            </ButtonLabelCreation>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export {AddFund}
