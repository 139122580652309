import {FC} from 'react'

type Props = {
  className?: any
  values: {
    length: number
    width: number
    height: number
    weight: number
    dimension_unit: string
    weight_unit: string
  }
}

const ParcelBadge: FC<Props> = ({className, values}) => {
  const dataParcel: any = []
  if (values?.[0]) {
    Object.entries(values).forEach(([key, value]: [any, any]) => {
      if (!isNaN(Number(key)) && typeof value === 'object') {
        dataParcel.push({
          dimension_unit: values?.dimension_unit,
          weight_unit: values?.weight_unit,
          ...value,
        })
      }
    })
  } else {
    dataParcel.push(values)
  }

  const formatValue = (value) => {
    return Number.parseFloat(value)
  }

  return dataParcel.map((parcel, index) => (
    <div
      className={`${className} w-120px d-flex justify-content-center py-2 mx-auto badge badge-light fw-bolder mb-2`}
      key={index}
    >
      {formatValue(parcel.length) +
        ' x ' +
        formatValue(parcel.width) +
        ' x ' +
        formatValue(parcel.height) +
        ' ' +
        parcel.dimension_unit}
      <br />
      {formatValue(parcel.weight) + ' ' + values.weight_unit}
    </div>
  ))
}

export {ParcelBadge}
