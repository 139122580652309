/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {cloneDeep, isEmpty} from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {convertUnit} from '../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {InputTextFormik, SelectFormik} from '../../../../../_gori/partials/widgets'
import {OPTION_DIMENSION_UNIT, OPTION_PACKAGE_TYPE, OPTION_WEIGHT_UNIT} from '../../core/_const'
import {PackageType} from './PackageType'

type Props = {
  formik: any
  disabled?: boolean
  className?: string
  hasGetPackage?: boolean
}

const PackageInfo: React.FC<Props> = ({formik, className = '', disabled, hasGetPackage = true}) => {
  const intl = useIntl()
  const {statesGlobal, getPackages} = useStatesGlobal()
  const carriers = statesGlobal.carriersPackage
  const carriersPackage = statesGlobal?.carriersPackage
  const packages = statesGlobal.packages

  // BEGIN: validate Total Weight
  const totalWeightPackage = useMemo(() => {
    let sum = 0
    Object.entries(formik.values.parcels).forEach(([key, value]: [any, any]) => {
      sum += Number.parseFloat(value?.weight) || 0
    })
    return sum
  }, [formik.values.parcels])

  const totalWeightCustoms = useMemo(() => {
    let sum = 0
    Object.entries(formik.values.custom_items).forEach(([key, value]: [any, any]) => {
      sum += Number.parseFloat(value?.weight) || 0
    })
    return sum
  }, [formik.values.custom_items])

  useEffect(() => {
    if (!formik.values.custom_items) return

    const valueConvert = convertUnit(
      {unit: 'oz', value: totalWeightCustoms},
      {unit: formik.values.parcels[0].weight_unit}
    )

    if (valueConvert > totalWeightPackage) {
      formik.setFieldValue('check_weight', true)
    } else {
      formik.setFieldValue('check_weight', false)
    }
  }, [
    formik.values.parcels[0].weight,
    formik.values.parcels[0].weight_unit,
    formik.values.custom_items,
  ])
  // END: validate Total Weight

  // BEGIN: Options package
  useEffect(() => {
    if (hasGetPackage) {
      getPackages()
    }

    return () => {}
  }, [getPackages])

  const optionsCustomPackage = useMemo(() => {
    if (!packages) {
      return [OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE]
    } else {
      const _optionPackages = packages.map((item) => {
        let objOption = {label: '', value: {}}
        objOption.label = item.name
        objOption.value = {
          length: item.length,
          width: item.width,
          height: item.height,
          dimension_unit: item.dimension_unit,
          package_id: item.id,
          package_type: '',
        }
        return objOption
      })
      return {
        label: OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label,
        options: _optionPackages,
      }
    }
  }, [packages])

  const optionsPackageTypes = useMemo(() => {
    if (isEmpty(carriersPackage)) return [OPTION_PACKAGE_TYPE.CARRIER_STANDARD_PACKAGE]

    const optionsCarriersPackage = carriersPackage.map((parent) => {
      return {
        label: parent.label,
        options: parent?.value?.map((item: any) => {
          return {
            label: item.label,
            value: {
              length: item.parcel.length,
              width: item.parcel.width,
              height: item.parcel.height,
              dimension_unit: item.parcel.dimension_unit,
              weight_limit: item.parcel.weight_limit,
              package_type: item.value,
              package_id: null,
            },
          }
        }),
      }
    })

    return optionsCarriersPackage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carriers])
  // END: Options service and package

  return (
    <div
      className={clsx({
        [className]: !!className,
      })}
    >
      <div>
        <div className='d-flex flex-wrap gap-2 justify-content-between align-items-center mt-10 mb-2'>
          <div className='fs-3 fw-bolder'>{intl.formatMessage({id: 'PACKAGE_INFO'})}</div>
          <div className={clsx('d-flex col-12 col-sm-6')}>
            <PackageType
              data={[
                OPTION_PACKAGE_TYPE.INPUT_DIMENSION,
                ...cloneDeep(optionsPackageTypes),
                optionsCustomPackage,
              ]}
              labelClassName='col-form-label text-muted fw-bolder'
              formik={formik}
              name='parcels.0.package_type'
              placeholder={intl.formatMessage({id: 'PACKAGE_TYPE'})}
              disabled={disabled}
            />
          </div>
        </div>
        {Object.entries(formik.getFieldProps('parcels').value).map(
          ([keyItem, valueItem]: any, index) => {
            const packageType = formik.values.parcels[0].package_type

            return (
              <div key={index}>
                <div className='row'>
                  <div className='col-12 d-flex flex-wrap'>
                    {!isEmpty(packageType) ? (
                      <div className='fs-5 fw-bold text-muted'>
                        {intl.formatMessage({id: 'DIMENSIONS'})}:{' '}
                        {`${valueItem?.length} x ${valueItem?.width} x ${valueItem?.height} ${valueItem?.dimension_unit}`}
                      </div>
                    ) : (
                      <>
                        {['length', 'width', 'height'].map((item, index) => {
                          return (
                            <div className='col-6 col-sm-3' key={index}>
                              <InputTextFormik
                                type='number'
                                min={0}
                                className={clsx('me-2', {'pe-none': disabled})}
                                labelClassName={'text-muted'}
                                label={item.charAt(0).toUpperCase()}
                                formik={formik}
                                name={`parcels.${keyItem}.${item}`}
                                disabled={disabled}
                              />
                            </div>
                          )
                        })}
                        <div className='col-6 col-sm-3'>
                          <SelectFormik
                            className={clsx({'pe-none': disabled})}
                            placeholder=''
                            label={intl.formatMessage({id: 'UNIT'})}
                            labelClassName={'text-muted'}
                            options={OPTION_DIMENSION_UNIT}
                            name={`parcels.${keyItem}.dimension_unit`}
                            formik={formik}
                            disabled={disabled}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className='col-12 d-flex flex-wrap mt-3'>
                    <div className='col-6 col-sm-3' key={index}>
                      <InputTextFormik
                        type='number'
                        min={0}
                        className={clsx('me-2', {'pe-none': disabled})}
                        labelClassName={'text-muted'}
                        label={intl.formatMessage({id: 'WEIGHT'})}
                        formik={formik}
                        name={`parcels.${keyItem}.weight`}
                        disabled={disabled}
                      />
                    </div>
                    <div className='col-6 col-sm-3'>
                      <SelectFormik
                        className={clsx({'pe-none': disabled})}
                        label={intl.formatMessage({id: 'UNIT'})}
                        placeholder=''
                        labelClassName={'text-muted'}
                        options={OPTION_WEIGHT_UNIT}
                        name={`parcels.${keyItem}.weight_unit`}
                        formik={formik}
                        disabled={disabled}
                      />
                    </div>
                    <div className='col-6 d-flex flex-column justify-content-end align-items-end'>
                      <div className='d-flex align-items-center text-muted mt-3'>
                        {intl.formatMessage({id: 'TOTAL_WEIGHT'})}:
                        <input name='check_weight' type='hidden' />
                        <span className='text-dark fs-1 mx-2'>{totalWeightPackage}</span>{' '}
                        {formik?.values?.parcels[0]?.weight_unit?.toUpperCase()}
                      </div>
                      {formik.errors?.check_weight && (
                        <div className='text-danger d-flex justify-content-end ps-10'>
                          {formik.errors?.check_weight}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>
    </div>
  )
}

export {PackageInfo}
