import {useCallback, useEffect, useRef} from 'react'
import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Footer} from './components/Footer'
import {Header} from './components/Header'

export function PrivacyPolicyPage() {
  const sectionsRef = useRef<{[key: string]: HTMLElement | null}>({})

  const getHeight = useCallback(() => {
    const navbar = document.querySelector('.fixed-top') as HTMLElement
    const navbarHeight = navbar?.offsetHeight
    return navbarHeight || 0
  }, [])

  const scrollToSection = useCallback(
    (targetId: string) => {
      const navHeight = getHeight()
      const targetElement = sectionsRef.current[targetId]

      if (targetElement) {
        const targetPosition =
          targetElement.getBoundingClientRect().top + window.scrollY - navHeight

        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        })
      }
    },
    [getHeight]
  )

  const setAnchors = useCallback(() => {
    const anchorLinks = document.querySelectorAll('a[href^="#"]')

    anchorLinks.forEach((link) => {
      link.addEventListener('click', (event: Event) => {
        event.preventDefault()
        const targetId = (link.getAttribute('href') as string).substring(1)
        scrollToSection(targetId)
      })
    })
  }, [scrollToSection])

  useEffect(() => {
    const handleScrollOnLoad = () => {
      if (window.location.hash) {
        const targetId = window.location.hash.substring(1)
        requestAnimationFrame(() => scrollToSection(targetId))
      }
    }

    handleScrollOnLoad()
    setAnchors()
  }, [scrollToSection, setAnchors])

  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <>
        <Header />

        {/* title */}
        <div className='mt-20'>
          <div className='container mt-20'>
            <div className='justify-content-end d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/check.png')}
                className='w-90px me-20'
                alt=''
              />
            </div>

            <div className='justify-content-center d-flex'>
              <div className='text-center display-2 fw-bolder pb-12' style={{color: '#16BAA8'}}>
                Privacy Policy
              </div>
            </div>

            <div className='justify-content-start d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/truck.png')}
                className='w-200px mx-5'
                alt=''
              />
            </div>
          </div>
        </div>

        {/* policy */}
        <div className='bg-white py-20'>
          <div className='container mb-10'>
            <div className='mt-5'>
              <p>
                <em>Effective Date: 8/16/2024</em>
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>1. Introduction</h3>

              <p>
                This Privacy Policy outlines the policies and procedures of Gori Company (“Gori”,
                “we”, “our”, or “us”) regarding the collection, use, and disclosure of information
                from customers, visitors and users of our sites, our other online products and our
                services that link to this Privacy Policy (collectively, the “Services”). This
                policy applies to information we collect or receive:
              </p>

              <ol className='paren-list-num'>
                <li>While on or using the Services;</li>

                <li>
                  When you interact with our advertising or applications on third-party websites and
                  services, if those applications or advertising include links to this policy;
                </li>

                <li>
                  Offline, in accordance with relevant law, including, but not limited to, the
                  California Consumer Privacy Act (CCPA).
                </li>
              </ol>

              <p>
                By using the Services, you consent to the collection, recording, organization,
                structuring, storage, adaptation or alteration, retrieval, consultation, use,
                disclosure by transmission, dissemination, or otherwise making available, alignment
                or combination, restriction, erasure, or destruction, and other uses of your
                information as described in this Privacy Policy.
              </p>

              <p>
                We respect your privacy and are committed to protecting your Personal Identifiable
                Information (PII) which is any data that can be used to identify a specific person.
                PII does not include aggregated or anonymized information, provided it does not and
                cannot identify you.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>2. Principles of the Gori Privacy Policy</h3>

              <p>
                Gori does not rent or sell your PII. We don't share your PII with anyone except to
                comply with the law, to provide a service you have requested, to protect our rights,
                or as provided in this Privacy Policy. We don't store PII on our servers unless
                required for the on-going operation of one of our Services.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>3. What Does This Privacy Policy Cover?</h3>

              <p>
                This Privacy Policy covers the treatment of PII gathered when you are using or
                accessing the Services. It also covers our treatment of any PII that our business
                partners share with us or that we share with our business partners. This Privacy
                Policy does not apply to the practices of third-parties that we do not own or
                control, including any third-party websites, services, and applications
                ("Third-Party Services") that you elect to access through the Services, or to
                individuals that we do not manage or employ. We encourage you to carefully review
                the privacy policies of any Third-Party Services you access.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>4. Country of Processing</h3>

              <p>
                We operate the Services from the United States of America (USA) and our data
                collection and processing activities take place predominantly in the USA.
                Accordingly, data collected from users when using our Services is collected in the
                USA. We store and otherwise process data, including PII, through Third-Party
                Services which may be located or which may operate in other countries.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>5. What Information Do We Collect and How It Is Collected?</h3>

              <p>
                While not all information that you provide or that we collect is PII, this policy
                includes all the information we collect, that you provide, or that is provided to us
                by Third-Party Services, including PII. In connection with certain aspects of the
                Services, we may request, collect and/or display some of your PII.
              </p>

              <p>We collect the following types of information from our users:</p>

              <ol className='paren-list-alpha' type='a'>
                <li className='mb-3'>
                  <strong>Contact Information.</strong> Your name, email address, phone number and
                  mailing address.
                </li>

                <li className='mb-3'>
                  <strong>Account Information.</strong> Name, username, email address, phone number,
                  billing address, shipping address and account login credentials to Third-Party
                  Services.
                </li>

                <li className='mb-3'>
                  <strong>User Content.</strong> Some features of the Services allow you to provide
                  content to the Services, such as written comments and videos.
                </li>

                <li className='mb-3'>
                  <strong>Transaction Information.</strong> Name, email address, phone number,
                  payment and billing information.
                </li>

                <li className='mb-3'>
                  <strong>Device Information.</strong> IP address, geolocation data, device
                  information, cookie information, browser type, browser version and browser
                  language.
                </li>

                <li className='mb-3'>
                  <strong>Session Information.</strong> Browsing behavior, referring sites, page
                  visits, page exits, clicks, cursor movements, searches on the Services and
                  customer service interaction.
                </li>

                <li className='mb-3'>
                  <strong>Cookie Information.</strong> Account information, Device Information and
                  Session Information as described in this section. More information on Gori's
                  Cookie Policy can be found in section 6,{' '}
                  <a href='#how-we-use'>How We Use Cookies and Similar Technologies</a>.
                </li>
              </ol>
            </div>

            <div className='pb-6' ref={(el) => (sectionsRef.current['how-we-use'] = el)}>
              <h3 className='pb-3'>6. How We Use Cookies and Similar Technologies</h3>

              <p>
                We use cookies and similar tracking technologies to track the activity on our
                Services. Cookies are files with a small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser from a website and
                stored on your device. Cookies are typically stored upon your consent when you visit
                a website. Other tracking technologies are also used such as beacons, tags and
                scripts to collect and track information and to improve and analyze our websites.
              </p>

              <p>Cookies used on websites are generally for two categories:</p>

              <ul>
                <li>
                  <strong>Functional.</strong> These cookies are required to enable basic
                  functionality of a website, such as your ability to sign in to the Services.
                </li>
                <li>
                  <strong>Performance.</strong> These cookies collect information that allows us to
                  improve functionality of our Services and your experience on the Services by
                  tracking usage.
                </li>
              </ul>

              <p>
                We do not use Targeting cookies or any third-party cookies on our Services for
                advertising purposes.
              </p>

              <p>
                You can choose to have your device or browser limit or disable the use of cookies.
                You can do this through your browser settings on each browser and device that you
                use. Each browser is different, so look at your browser Help menu to learn the
                correct way to modify your cookies. If you turn cookies off, you may not have access
                to features that make the Services more efficient and some of the features of the
                Services may not function properly.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>7. How We Use the Information That We Collect</h3>

              <p>
                We and authorized third-parties engaged by us to provide services may use the
                information we collect on the basis of various legal grounds, which are more fully
                explained in this section. For example, we may use the information for compliance
                purposes, in situations where you provide consent for its use, as might be required
                to perform a contract with you, or for other legitimate business purposes. More
                specifically, legitimate business purposes may include the following:
              </p>

              <ol className='paren-list-alpha' type='a'>
                <li>
                  To enable us to operate the Services and to enable you to use them efficiently;
                </li>
                <li>
                  Insofar as permitted under applicable law, to communicate with you in relation to
                  our services and legal practice;
                </li>
                <li>To personalize, test, monitor, improve and upgrade the Services;</li>
                <li>To assist law enforcement and respond to subpoenas;</li>
                <li>
                  To meet our legal obligations and the regulatory requirements to which we are
                  subject, for loss prevention purposes and to protect and enforce our rights and
                  meet our obligations to third-parties; and
                </li>
                <li>
                  For our internal business purposes, such as compiling and analyzing (on an
                  aggregated and non-personally identifiable basis) usage information for general
                  operational, statistical and business purposes.
                </li>
              </ol>

              <p>
                We may combine or aggregate any PII we collect for any of the above purposes.  We
                anonymize your PII in such a way that you may not be re-identified by us or any
                other organization.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>8. How We Share Your PII</h3>

              <p>
                When you access or use the Services, we collect, use, share, and otherwise process
                your PII as described in this Privacy Policy. Gori does not disclose PII to any
                Third-Party Services without your consent or as part of your normal operations in
                using the Services. If you access Third-Party Services, such as Google through the
                Services to login to the Services or to share information about your experience
                using the Services with others, these Third-Party Services may be able to collect
                information about you, including information about your activity using the Services,
                and they may notify your connections on the Third-Party Services about your use of
                the Services, in accordance with their own privacy policies. Please review those
                policies carefully to understand how those parties will treat your information.
                Outside of such mechanisms, the Services do not disclose PII to any Third-Party
                Services.
              </p>

              <p>We may disclose your PII when it is necessary:</p>

              <ul>
                <li>For us to provide the Services to you.</li>
                <li>To protect the vital interests of yourself or others.</li>
                <li>
                  For compliance with a legal obligation (such disclosures may be carried out
                  without notice to you)
                </li>
                <li>In the public interest.</li>
                <li>For the purposes of our legitimate interests or those of a third-party.</li>
              </ul>

              <p>
                Some of your activity on and through the Services is public by default. This may
                include, but is not limited to, content you have posted publicly on the Services or
                otherwise through the Services. Such content becomes publicly available and can be
                collected and used by others.
              </p>
              <p>
                We do not use your email address or other PII to send marketing messages unless
                you've opted in to receive such messages. We may use your email address without
                further consent for non-marketing or administrative purposes (such as notifying you
                of major changes in the Services or for customer service purposes). We use both your
                PII and certain non-PII to improve the quality and design of the Services and to
                create new features, functionality, and services.
              </p>
              <p>
                Gori will not share PII about you with other people or companies without your
                consent except to provide products or services you've requested and under the
                following circumstances: Gori may be required to disclose PII under special
                circumstances, such as to comply with legal processes or to establish or exercise
                our legal rights.
              </p>
              <p>
                Gori reserves the right to disclose PII and/or non-PII that Gori believes is
                appropriate or necessary to enforce our Terms, take precautions against liability,
                to investigate and defend itself against any third-party claims or allegations, to
                assist government enforcement agencies, to protect the security or integrity of the
                Services, and to protect the rights, property, or personal safety of Gori, our
                users, or others.
              </p>

              <p>
                <strong>Third-Party Services.</strong> We may share your information, including PII,
                with Third-Party Services who perform various functions to enable us to provide our
                Services and help us operate our business, including, but not limited to, postal and
                delivery carriers, as well as platforms and software that provide the following:
                shopping and payment processing; website design; email, telephone and SMS (text
                messaging) communications; advertising; fraud detection and prevention; customer
                care; third-party surveys; or analytics.
              </p>

              <p>
                <strong>Carrier Services.</strong> Use of carrier services via our Services is at
                your own risk. We are not responsible for your use of carrier services. Your use of
                carrier services is as a direct customer of the specific carrier of your choosing
                and you agree to be bound by the terms and conditions of that carrier for use of
                services. By providing information to certain carrier services, you understand and
                consent to the collection, use, processing disclosure and transfer of such
                information and which they may have different data protection practices. Your
                interactions with these carrier services are governed by each carrier's privacy
                policy referenced in the following privacy statement sections.
              </p>

              <p>
                For more information regarding other specific carriers you may use via our Services,
                please see the privacy policies provided by each specific carrier you select.
              </p>
              <p>
                <strong>United States Postal Service (USPS) Privacy Statement.</strong> We collect
                certain information about you on behalf of the USPS. The USPS also may send us
                tracking information from their scans of your mail and packages in the mailstream.
                Our uses for the information include informing you about the status of your mail and
                packages through options like USPS tracking, fraud detection and aggregating the
                information to help the USPS and us provide you better service. Information
                collected on behalf of USPS is used for the regulatory oversight function of USPS.
              </p>

              <p>
                As part of our providing services of the USPS, the USPS requires that you agree to
                the following USPS Privacy Act Statement and to provide information about yourself
                that will be maintained in a Privacy Act System of Records by the USPS.
              </p>

              <p>
                <em>Privacy Act Statement:</em> Your information will be used to facilitate the
                purchase of USPS postage and fulfill transactional reporting requirements for USPS
                postage systems. Collection is authorized by 39 U.S.C. 401, 403, and 404. Providing
                the information is voluntary, but if not provided, your transaction may not be
                processed. The USPS does not disclose your information to third-parties without your
                consent, except to facilitate the transaction, to act on your behalf or request, or
                as legally required. This includes the following limited circumstances: to a
                congressional office on your behalf; to financial entities regarding financial
                transaction issues; to a USPS auditor; to entities, including law enforcement, as
                required by law or in legal proceedings; and to contractors and other entities
                aiding us to fulfill the Services (service providers). USPS shall use the
                aforementioned information in accordance with the USPS Privacy Policy:
              </p>

              <p>
                <a
                  href='https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm'
                  target='_blank'
                  rel='noreferrer'
                  style={{paddingLeft: '30px'}}
                >
                  https://about.usps.com/who/legal/privacy-policy/full-privacy-policy.htm
                </a>
              </p>

              <p>
                <strong>FedEx Privacy Statement:</strong> Participant acknowledges and agrees that
                their identity, contact information, account information and FedEx shipping
                information and data may be provided by FedEx to Gori and by Gori to FedEx. FedEx
                shall use the aforementioned information in accordance with the FedEx Privacy
                Notice:
              </p>

              <p>
                <a
                  href='https://www.fedex.com/en-us/trust-center/privacy.html'
                  target='_blank'
                  rel='noreferrer'
                  style={{paddingLeft: '30px'}}
                >
                  https://www.fedex.com/en-us/trust-center/privacy.html
                </a>
              </p>

              <p>
                <strong>UPS Privacy Statement:</strong> Participant acknowledges and agrees that
                their identity, contact information, account information and FedEx shipping
                information and data may be provided by UPS to Gori and by Gori to UPS. UPS shall
                use the aforementioned information in accordance with the UPS Privacy Notice:
              </p>

              <p>
                <a
                  href='https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/privacy-notice.page'
                  target='_blank'
                  rel='noreferrer'
                  style={{paddingLeft: '30px'}}
                >
                  https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions/privacy-notice.page
                </a>
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>9. How Long We Retain Your Information</h3>

              <p>
                Some features of the Services allow you to provide content to the Services, such as
                written comments. All content submitted by you to the Services may be retained by us
                indefinitely, even after you deactivate your account, unless the content contains
                PII, in which case you can request that the content be provided to you and/or
                deleted. We may continue to disclose such content to third-parties in a manner that
                does not reveal PII, as described in this Privacy Policy.
              </p>
              <p>
                Because we need your PII to provide you the Services, we generally keep your PII,
                including sensitive PII, while you use the Services or until you tell us to delete
                your information. We may also keep PII to comply with legal obligations or protect
                our or other's interests.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>10. Data Security and Change of Control</h3>

              <p>
                Your Account information is password-protected. Gori uses commercially reasonable
                physical, managerial, and technical safeguards to preserve the integrity and
                security of your PII. We cannot ensure or warrant the security of any information
                you transmit to Gori and you do so at your own risk. If Gori learns of a security
                system breach, we may notify you electronically so you can take appropriate
                protective steps. Gori may post a notice to the Services if a security breach
                occurs.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>11. What Information of Yours Can You Access?</h3>

              <p>
                As required by applicable laws, and subject to any permitted exceptions and
                limitations, we will comply with any verified data subject access request you submit
                with respect to your PII. Please submit a verifiable data subject access request to
                us as described in section 18, <a href='#contact-us'>Contact Us</a>.
              </p>
              <p>
                If you are a registered user, you can access information associated with your
                Account by logging into the Services. Registered and unregistered users can access
                and delete cookies through their web browser settings. California Privacy Rights:
                Under California Civil Code sections 1798.83-1798.84, California residents are
                entitled to ask us for a notice identifying the categories of PII which we share
                with our affiliates and/or third-parties for marketing purposes, and providing
                contact information for such affiliates and/or third-parties. If you are a
                California resident and would like a copy of this notice, please submit a written
                request to the following address:
              </p>

              <p style={{paddingLeft: '30px'}}>
                ATTN: Data Protection Officer
                <br />
                1047 S Placentia Avenue
                <br />
                Fullerton, CA 92831
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>12. What Rights Do You Have Over Your Information?</h3>
              <p></p>
              <p>
                You can always opt not to disclose certain information to us, even though it may be
                needed to take advantage of some of our features. You can deactivate your Account.
                Please note that we will need to verify that you have the authority to deactivate
                the Account, and activity generated prior to deactivation will remain stored by us
                and may be publicly accessible.
              </p>
              <p>
                You can ask us to confirm whether or not your PII is being processed and, if so, to
                provide you with access to the information and an explanation of the purposes of the
                processing, the categories of personal data concerned, the categories of recipients
                of the data, and the period for which the data are stored. You can also withdraw
                your consent or object to the processing of your PII, request that we correct or
                erase your PII, or obtain a copy of your PII from us.
              </p>
              <p>
                Depending on where you live, how you use the Services, and subject to certain
                exceptions, you may have some or all of the following rights:
              </p>

              <ul className='my-3'>
                <li>
                  <strong>Right to Know:</strong> The right to request that we disclose to you the
                  PII we collect, use, or disclose about you, and information about our data
                  practices;
                </li>
                <li>
                  <strong>Right to Correct:</strong> The right to request that we correct inaccurate
                  PII that we maintain about you;
                </li>
                <li>
                  <strong>Right to Delete:</strong> The right to request that we delete PII that we
                  have collected about you;
                </li>
                <li>
                  <strong>Right to Opt-Out:</strong> The right to opt-out of the sale or sharing of
                  their PII; and
                </li>
                <li>
                  <strong>Right to Non-Discrimination:</strong> The right to non-discrimination for
                  exercising your CCPA rights.
                </li>
                <li>
                  <strong>Right to Limit.</strong> The right to limit the use and disclosure of PII
                  collected about you.
                </li>
              </ul>

              <p>
                To exercise your rights, including the "right to know" and "right to delete," please
                submit a request by emailing our Data Protection Officer at{' '}
                <a className='fw-bold' href='mailto:dsar@goricompany.com'>
                  dsar@goricompany.com
                </a>
                .
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>13. What Are Your California Privacy Rights</h3>

              <p>
                California Civil Code Section § 1798.83 permits California residents with whom we
                have an established business relationship to request certain information regarding
                our disclosure of PII to Third-Party Services for their direct marketing purposes.
                To make such a request, please send an email to dsar@goricompany.com, or write us
                at:
              </p>

              <p style={{paddingLeft: '30px'}}>
                Gori Company
                <br />
                ATTN: Data Protection Officer
                <br />
                1047 S Placentia Avenue
                <br />
                Fullerton, CA 92831
              </p>

              <p>
                For more information about California resident privacy rights, including the rights
                of California residents under the CCPA, please see the following section,{' '}
                <a href='#california-consumer-privacy-act'>California Consumer Privacy Act</a>.
              </p>
            </div>

            <div
              className='pb-6'
              ref={(el) => (sectionsRef.current['california-consumer-privacy-act'] = el)}
            >
              <h3 className='pb-3'>14. California Consumer Privacy Act</h3>

              <p>
                The following disclosures are made pursuant to the CCPA. These disclosures
                supplement any privacy notices we previously or contemporaneously provided to you.
                The below chart reflects the categories of PII we have collected from California
                consumers over the past twelve months, the categories of sources from which the
                information was collected, the business or commercial purpose for which the
                information was collected, and the categories of third-parties with whom we shared
                that information.
              </p>

              <table className='table table-bordered privacy-table'>
                <thead className='text-white fw-boldest'>
                  <tr>
                    <th className='bg-primary px-5 align-middle fixed-col' scope='col'>
                      <strong>Categories of Personal Identifiable Information Collected</strong>
                    </th>
                    <th className='bg-primary px-5 align-middle' scope='col'>
                      <strong>Sources of Collection</strong>
                    </th>
                    <th className='bg-primary px-5 align-middle' scope='col'>
                      <strong>Business/Commercial Purpose for Collection</strong>
                    </th>
                    <th className='bg-primary px-5 align-middle' scope='col'>
                      <strong>
                        Categories of Third-Parties Receiving Personal Identifiable Information
                      </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='px-5 bg-white align-middle fixed-col'>
                      Identifiers: Name, username, mailing address, IP address, email address and
                      account name.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      You. Devices you use to access our Services.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      To provide you with our Services and respond to your questions. To understand
                      the Services considered by our customers and to make improvements to our
                      offerings. To identify and repair errors that impair existing or intended
                      functionality. To detect security incidents and protect against malicious,
                      deceptive, fraudulent, or illegal activity.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      Partners and Third-Parties in Connection with Investigations, Safety and
                      Complying with Legal Obligations.
                    </td>
                  </tr>
                  <tr>
                    <td className='px-5 bg-white align-middle fixed-col'>
                      Customer records information: Name, signature, mailing address, telephone
                      number, bank account number and other financial information.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      You. Devices you use to access our Services.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      To provide you with our Services and respond to your questions. To understand
                      the Services considered by our customers and to make improvements to our
                      offerings. To identify and repair errors that impair existing or intended
                      functionality. To detect security incidents and protect against malicious,
                      deceptive, fraudulent, or illegal activity.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      Partners and Third-Parties in Connection with Investigations, Safety and
                      Complying with Legal Obligations.
                    </td>
                  </tr>
                  <tr>
                    <td className='px-5 bg-white align-middle fixed-col'>
                      Internet or other electronic network activity information: Information
                      regarding your interaction with our Services.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      You. Devices you use to access our Services.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      To provide you with our Services and respond to your questions. To understand
                      the Services considered by our customers and to make improvements to our
                      offerings. To identify and repair errors that impair existing or intended
                      functionality. To detect security incidents and protect against malicious,
                      deceptive, fraudulent, or illegal activity.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      Partners and Third-Parties in Connection with Investigations, Safety and
                      Complying with Legal Obligations.
                    </td>
                  </tr>
                  <tr>
                    <td className='px-5 bg-white align-middle fixed-col'>Geolocation data.</td>
                    <td className='px-5 bg-white align-middle'>
                      You. Devices you use to access our Services.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      To provide you with our Services and respond to your questions. To understand
                      the Services considered by our customers and to make improvements to our
                      offerings. To identify and repair errors that impair existing or intended
                      functionality. To detect security incidents and protect against malicious,
                      deceptive, fraudulent, or illegal activity.
                    </td>
                    <td className='px-5 bg-white align-middle'>
                      Partners and Third-Parties in Connection with Investigations, Safety and
                      Complying with Legal Obligations.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>15. Changes and Updates to This Privacy Policy</h3>

              <p>
                This Privacy Policy may be revised periodically with or without notice to you and
                this will be reflected by the "Effective Date" above. Please revisit this page to
                stay aware of any changes. In general, we only use your PII in the manner described
                in the Privacy Policy in effect when we received the PII you provided. Your
                continued use of the Services constitutes your agreement to this Privacy Policy and
                any future revisions. For revisions to this Privacy Policy that may be materially
                less restrictive on our use or disclosure of the PII you have already provided to
                us, we will attempt to obtain your consent before implementing such revisions with
                respect to such information.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>16. What If You Have Questions or Concerns?</h3>

              <p>
                If you have any questions or concerns regarding privacy using the Services, please
                send us a detailed message to{' '}
                <a className='fw-bold' href='mailto:gorisupport@goricompany.com'>
                  gorisupport@goricompany.com
                </a>
                . We will make every effort to resolve your concerns.
              </p>
            </div>

            <div className='pb-6'>
              <h3 className='pb-3'>17. Data Privacy Framework</h3>

              <p>
                Gori's Privacy Policy conforms to the EU-U.S. Data Privacy Framework Principles
                ("DPF Principles") as set forth by the U.S. Department of Commerce, declares Gori's
                commitment to complying with the DPF Principles and is enforceable under U.S. law.
                Please visit{' '}
                <a
                  className='fw-bold'
                  href='https://www.dataprivacyframework.gov/list'
                  rel='noreferrer'
                  target='_blank'
                >
                  https://www.dataprivacyframework.gov/list
                </a>{' '}
                to check the current status of Gori's certification with the U.S. Department of
                Commerce's International Trade Administration (ITA) that it participates in and
                complies with the DPF Principles. To learn more about the DPF program, please visit{' '}
                <a
                  className='fw-bold'
                  href='www.dataprivacyframework.gov'
                  rel='noreferrer'
                  target='_blank'
                >
                  www.dataprivacyframework.gov
                </a>
                .
              </p>

              <p>In compliance with the DPF Principles, this Privacy Policy sets out:</p>

              <ul className='my-3'>
                <li>
                  The types of PII we collect ("What Information Do We Collect and How It Is
                  Collected?")
                </li>
                <li>
                  The purposes for which we collect and use PII ("What Information Do We Collect and
                  How It Is Collected?")
                </li>
                <li>
                  The types of third-parties to which we disclose PII, and the purposes for which we
                  do so ("Information Sharing And Disclosure")
                </li>
                <li>
                  The right of individuals to access their PII ("What Information of Mine Can I
                  Access")
                </li>
                <li>
                  The choices and means we offer individuals for limiting the use and disclosure of
                  their PII ("What Rights Do I Have Over My Information")
                </li>
                <li>Our liability for onward transfers to third-parties</li>
              </ul>

              <p>
                Gori is responsible for the processing of PII it receives under the DPF and
                subsequently transfers to a third-party acting as an agent on its behalf, if any.
                Gori complies with the DPF Principles for all onward transfers of personal data from
                the EU, including the onward transfer liability provisions.
              </p>

              <p>
                E.U. data subjects may contact us for the handling of complaints, access requests,
                and any other issues arising under Data Privacy Framework as specified in section
                18, <a href='#contact-us'>Contact Us</a>. If you have an unresolved privacy or data
                use concern that we have not addressed satisfactorily, please contact our U.S.-based
                third-party dispute resolution provider (free of charge)
                https://www.jamsadr.com/dpf-dispute-resolution. As further explained in the DFP
                Principles, a binding arbitration option will also be made available to you in order
                to address residual complaints not resolved by any other means. Gori is subject to
                the investigatory and enforcement powers of the U.S. Federal Trade Commission (FTC).
                In certain situations, Gori may be required to disclose PII in response to lawful
                requests by public authorities, including to meet national security or law
                enforcement requirements.
              </p>
            </div>

            <div className='pb-6' ref={(el) => (sectionsRef.current['contact-us'] = el)}>
              <h3 className='pb-3'>18. Contact Us</h3>

              <p>
                If you have any questions or comments about this policy, concerns about our
                processing of your PII, need to access your PII or have any other question related
                to data protection and privacy, please submit your request by emailing{' '}
                <a className='fw-bold' href='mailto:dsar@goricompany.com'>
                  dsar@goricompany.com
                </a>{' '}
                or writing to us at the following address:
              </p>

              <p style={{paddingLeft: '30px'}}>
                Gori Company
                <br />
                ATTN: Data Protection Officer
                <br />
                1047 S Placentia Avenue
                <br />
                Fullerton, CA 92831
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </>
    </CSSTransition>
  )
}
