import clsx from 'clsx'
import {getIn, useFormik} from 'formik'
import {lowerCase, some} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import {COLORS} from '../../core/_const'
import OrderService from '../../core/_requests'

type Props = {
  show: boolean
  dataInit: any
  dataModal: any
  handleClose: () => void
  handleActionSuccess: (key, value) => void
}

const EditTagModal: React.FC<Props> = ({
  show,
  dataInit = [],
  dataModal = {},
  handleClose,
  handleActionSuccess,
}) => {
  const intl = useIntl()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)

  const OPTION_COLOR = COLORS.map((item) => {
    item['active'] = !!some(
      dataInit.filter((item) => item.id !== dataModal.id),
      {color: item.color}
    )
    return item
  })

  const isDuplicateName = useCallback(
    (value) => {
      return dataInit
        .filter((item) => item.id !== dataModal.id)
        .some((item) => lowerCase(item.name) === lowerCase(value?.trim()))
    },
    [dataInit, dataModal.id]
  )

  const initValidateSchema = useMemo(() => {
    return {
      name: stringYup(50, 'TAG_NAME').test(
        'is-unique',
        intl.formatMessage(
          {id: 'INPUT_ALREADY_EXISTS'},
          {input: intl.formatMessage({id: 'TAG_NAME'})}
        ),
        function (value) {
          return !isDuplicateName(value)
        }
      ),
      color: stringYup(20, 'TAG_COLOR'),
    }
  }, [intl, isDuplicateName, stringYup])

  const [validateSchema] = useState<any>(initValidateSchema)
  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  const validationSchema = Yup.object().shape(validateSchema)
  const formik = useFormik({
    initialValues: {
      id: dataModal?.id || null,
      name: dataModal?.name || null,
      color: dataModal?.color || null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const config = {cancelToken: newCancelToken()}
      try {
        setIsLoadingForm(true)
        const {
          tag: {id, name, color},
        } = await OrderService.editTag(formik.values.id, formik.values, config)
        if (id) {
          handleActionSuccess('edit', {id: id, name: name, color: color})
          toast.success(intl.formatMessage({id: 'UPDATED_SUCCESSFULLY'}))
          handleCloseModal()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setIsLoadingForm(false)
      }
    },
  })

  const handleSelectColor = (color) => {
    formik.setFieldValue('color', color)
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_edit_tag'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-600px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'EDIT_TAG'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='scroll-y'>
            <div className='col-md-12'>
              <div className='d-flex'>
                <InputTextFormik
                  className='col-8'
                  labelClassName='col-4 col-form-label'
                  required
                  label={intl.formatMessage({id: 'TAG_NAME'})}
                  formik={formik}
                  name='name'
                />
              </div>
            </div>
            <div className='col-md-12 d-flex my-2'>
              <label className={'col-4 form-label col-form-label required'}>
                {intl.formatMessage({id: 'TAG_COLOR'})}
              </label>
              <div className='col-8'>
                <div className='d-flex align-items-center justify-content-center flex-wrap'>
                  {OPTION_COLOR.map((item: any, index) => (
                    <div
                      key={index}
                      className={clsx(
                        'd-flex justify-content-center align-items-center w-50px h-50px m-2 rounded-circle',
                        {
                          'opacity-50 cursor-no-drop': item.active,
                        }
                      )}
                      style={{backgroundColor: item.color}}
                      onClick={() => !item.active && handleSelectColor(item.color)}
                    >
                      {(item.active || formik.values.color) && (
                        <KTSVG
                          path={`/media/gori/orders/${
                            (item.active && 'delete.svg') ||
                            (formik.values.color === item.color && 'valid.svg')
                          }`}
                          className={clsx('svg-icon-4', {
                            'text-dark': item.active,
                            'text-white': formik.values.color === item.color,
                          })}
                        />
                      )}
                    </div>
                  ))}
                </div>
                {getIn(formik.touched, 'color') && getIn(formik.touched, 'color') && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      <span role='alert'>{getIn(formik.errors, 'color')}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={formik.submitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {EditTagModal}
