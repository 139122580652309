import clsx from 'clsx'
import {cloneDeep} from 'lodash'
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {DEFAULT_COLOR} from '../../../constants'

type Props = {
  onChange?: any
  className?: string
  labelClassName?: string
  classNameSelect?: string
  label?: string | null
  options: Array<any>
  value?: any
  required?: boolean
  name?: string
  placeholder?: string
  emptyDefault?: boolean
  disabled?: boolean
  styleInput?: any
  isSearchable?: boolean
  hasUseIntl?: boolean
  styleMenu?: any
  isClickMulti?: boolean
}

const SelectNoFormik: FC<Props> = ({
  onChange = undefined,
  className = '',
  labelClassName = '',
  classNameSelect = '',
  label = null,
  options = [],
  value,
  required = false,
  name,
  placeholder = '',
  emptyDefault = false,
  disabled,
  styleInput,
  styleMenu,
  isSearchable = true,
  hasUseIntl = false,
  isClickMulti = false,
}) => {
  const customStyles = {
    menuPortal: (base) => ({...base, zIndex: 9999, ...styleMenu}),
    option: (styles, {isSelected, isDisabled}) => {
      return {
        ...styles,
        minHeight: 30,
        backgroundColor: isSelected && '#14B8A6',
        '&:hover': {
          background: !isSelected && '#f0fdfa',
        },
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }
    },
    control: (base) => ({
      ...base,
      minHeight: 40,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 3.5rem center',
      backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
      background: '#ffffff',
      borderColor: DEFAULT_COLOR,
      '&:hover': {
        borderColor: DEFAULT_COLOR,
      },
      boxShadow: DEFAULT_COLOR,
      fontSize: '1.1rem',
      fontWeight: '500',
      color: '#5e6278',
      lineHeight: '1.5',
      padding: '3px',
      ...styleInput,
    }),
  }
  const intl = useIntl()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const selectRef = useRef(null)

  let selectOptions = useMemo(() => {
    let _options = cloneDeep(options)
    if (hasUseIntl) {
      _options = _options.map((item) => {
        item.label = intl.formatMessage({id: item.label})
        return item
      })
    }

    if (emptyDefault) {
      return [{value: '', label: '', disabled: false}, ..._options]
    }
    return _options
  }, [emptyDefault, hasUseIntl, intl, options])

  // Handle click outside to close the menu
  useEffect(() => {
    if (!isClickMulti) return
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !(selectRef.current as any).contains(event.target)) {
        setMenuIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isClickMulti, selectRef])

  return (
    <>
      {label && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <div className={clsx(className, {'cursor-no-drop': disabled})} ref={selectRef}>
        <Select
          name={name}
          className={classNameSelect}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary: '#14B8A6',
            },
          })}
          options={selectOptions}
          value={value}
          isDisabled={disabled}
          placeholder={placeholder}
          isSearchable={isSearchable}
          {...(onChange ? {onChange: onChange} : {})}
          isOptionDisabled={(option) => option.disabled === true}
          {...(isClickMulti ? {menuIsOpen: menuIsOpen, onMenuOpen: () => setMenuIsOpen(true)} : {})}
        />
      </div>
    </>
  )
}

export {SelectNoFormik}
