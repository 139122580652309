import clsx from 'clsx'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import SettingsService from '../../../../core/_requests'
import {Button, ButtonBack} from '../../../../../../../_gori/partials/widgets'
import {StorageHelpers} from '../../../../../../../_gori/helpers'
import {SettingsConfig} from '../../../../core/_const'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../../../_gori/hooks/UseCancelToken'
import * as Yup from 'yup'
import {OrderType} from '../../../../../orders/core/_const'
import {StoresListing} from '../StoresListing'

const ConnectEbay: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {newCancelToken} = useCancelToken()

  const CURRENT_COMPANY_STORAGE =
    process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  const validationSchema = Yup.object().shape({})

  const initialValues = {
    store_type: SettingsConfig.EBAY_KEY,
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true)
      const company = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE)
      const config = {cancelToken: newCancelToken()}
      StorageHelpers.setItemCookies('slug', company?.slug, {
        path: '/',
        domain: BASE_DOMAIN?.split(':')[0],
      })
      let path = '/ebay/url-auth'

      await SettingsService.getUrlAuthorizationLinkEbay(path, config)
        .then((res) => {
          setIsLoading(false)
          window.location.replace(res)
        })
        .catch((error) => {
          toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
        })
        .finally(() => {})
    },
  })

  const onGoBack = () => {
    navigate('/settings/connections')
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card p-10 rounded-bottom-0'>
          <div className='card-title'>
            <div className='d-flex gap-4 align-items-center'>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={onGoBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} Ebay
              </span>
            </div>
          </div>
          <div className='text-end'>
            <div>
              <Button
                className={clsx('btn btn-primary text-end', {
                  'pe-none': isLoading,
                  'cursor-no-drop': isLoading,
                })}
                loading={isLoading}
                loadingText={intl.formatMessage({id: 'CONNECT'})}
                label={intl.formatMessage({id: 'CONNECT'})}
                event={formik.submitForm}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
      <StoresListing storeType={OrderType.EBAY} />
    </>
  )
}

export {ConnectEbay}
