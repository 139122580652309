import clsx from 'clsx'
import {upperCase} from 'lodash'
import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_gori/helpers'
import {InputTextFormik} from '../../../../_gori/partials/widgets'
import {OrdersConfig, OrderType} from '../core/_const'

type Props = {
  disabled?: any
  formik: any
  handleAddNewItem?: any
  handleDeleteItem?: any
  data?: any
}

const ItemPackages: React.FC<Props> = ({
  formik,
  data,
  disabled,
  handleAddNewItem,
  handleDeleteItem,
}) => {
  const intl = useIntl()
  const storeType = data?.store_type || OrderType.SHIPBAE

  const itemsShadow = useMemo(() => {
    if (!data?.order_items) return null
    const mergedItemsArray = data?.order_items?.reduce((acc, item) => {
      const existingItem = acc.find((i) => i.sku === item.sku)
      if (existingItem) {
        existingItem.quantity += item.quantity
      } else {
        acc.push({...item})
      }
      return acc
    }, [])

    return mergedItemsArray.reduce((result, item, index) => {
      result[index] = item
      return result
    }, {})
  }, [data?.order_items])

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <span className='text-muted fw-bold'>{intl.formatMessage({id: 'ITEM'})}</span>
        {!disabled && (
          <div
            className={clsx(
              'd-flex align-items-center text-muted text-hover-primary fw-bolder cursor-pointer',
              {
                'cursor-no-drop': disabled,
              }
            )}
            onClick={() => !disabled && handleAddNewItem('order_items')}
          >
            <span className='fs-2 me-1'>+</span>
            {intl.formatMessage({id: 'ADD_ITEM'})}
          </div>
        )}
      </div>
      {storeType === OrderType.TIKTOK &&
        Object.entries(itemsShadow).map(([keyItem, valueItem]: [any, any], index) => {
          const length = Object.entries(itemsShadow).length
          const last = index + 1 === length
          return (
            <>
              <div
                className={clsx('d-flex align-items-center pt-2', {'pb-5': !last})}
                key={keyItem}
              >
                <div className='d-flex flex-wrap flex-fill'>
                  {Object.entries(OrdersConfig.ITEMS).map(([key, value], index) => {
                    return (
                      <div
                        className={clsx(
                          {'col-md-3': key !== 'TITLE' && key !== 'SKU'},
                          {'col-md-12': key === 'TITLE'},
                          {'col-md-6': key === 'SKU'}
                        )}
                        key={index}
                      >
                        <div className={clsx('mb-2 mx-2')}>
                          <label
                            className={`form-label col-form-label text-nowrap text-muted ${
                              value.value !== OrdersConfig.ITEMS.SKU.value ? 'required' : ''
                            }`}
                          >
                            {intl.formatMessage({id: upperCase(value.label)})}
                          </label>
                          {(key === 'TITLE' || key === 'SKU') && (
                            <textarea
                              disabled={true}
                              rows={1}
                              className={
                                'form-control input-custom__input overflow-hidden cursor-no-drop resize-none'
                              }
                              value={valueItem[value.value]}
                            />
                          )}
                          {key !== 'TITLE' && key !== 'SKU' && (
                            <input
                              disabled={true}
                              type='text'
                              className={'form-control input-custom__input cursor-no-drop'}
                              value={valueItem[value.value]}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='col-1'>
                  <div
                    className={clsx(
                      'd-flex justify-content-center text-hover-danger cursor-pointer',
                      {'cursor-no-drop': disabled}
                    )}
                    onClick={() => !disabled && handleDeleteItem('order_items', keyItem)}
                  >
                    <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                  </div>
                </div>
              </div>
              {!last && <hr className='bg-gray-600'></hr>}
            </>
          )
        })}
      {formik?.values?.order_items &&
        storeType !== OrderType.TIKTOK &&
        Object.entries(formik.values.order_items).map(([keyItem, valueItem], index) => {
          const length = Object.entries(formik.values.order_items).length
          const last = index + 1 === length
          return (
            <>
              <div
                className={clsx('d-flex align-items-center pt-2', {'pb-5': !last})}
                key={keyItem}
              >
                <div className='d-flex flex-wrap flex-fill'>
                  {Object.entries(OrdersConfig.ITEMS).map(([key, value], index) => {
                    return (
                      <div
                        className={clsx(
                          {'col-md-3': key !== 'TITLE' && key !== 'SKU'},
                          {'col-md-12': key === 'TITLE'},
                          {'col-md-6': key === 'SKU'}
                        )}
                        key={index}
                      >
                        <div className={clsx('mb-2 mx-2')}>
                          <InputTextFormik
                            {...(OrdersConfig.ITEMS[key].type === 'number'
                              ? {min: 0, max: 1000000}
                              : '')}
                            type={OrdersConfig.ITEMS[key].type}
                            isTextArea={key === 'TITLE' || key === 'SKU'}
                            labelClassName='col-form-label text-nowrap text-muted'
                            label={intl.formatMessage({id: upperCase(value.label)})}
                            formik={formik}
                            name={`order_items.${keyItem}.${value.value}`}
                            isInteger={value.value === OrdersConfig.ITEMS.QUANTITY.value}
                            required={value.value === OrdersConfig.ITEMS.TITLE.value}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                {!disabled && (
                  <div className='col-1'>
                    <div
                      className={clsx(
                        'd-flex justify-content-center text-hover-danger cursor-pointer',
                        {'cursor-no-drop': disabled}
                      )}
                      onClick={() => !disabled && handleDeleteItem('order_items', keyItem)}
                    >
                      <KTSVG path='/media/gori/orders/empty.svg' small={false} />
                    </div>
                  </div>
                )}
              </div>
              {!last && <hr className='bg-gray-600'></hr>}
            </>
          )
        })}
    </div>
  )
}

export {ItemPackages}
