import {useState, useEffect, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_gori/helpers'
import {Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

export function Security() {
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [isOverflowing, setIsOverflowing] = useState<boolean[]>([])

  const cardData = [
    {
      title: 'Authorization',
      description:
        "Role-Based Access Control (RBAC): Restricts access to certain parts of the application based on the user's role (e.g., admin, user).",
      imgSrc: '/media/gori/landing/auth-icon.svg',
    },
    {
      title: 'Input Validation',
      description:
        'Sanitization: Ensure that user inputs are sanitized to prevent SQL injection, cross-site scripting (XSS), and other injection attacks.',
      imgSrc: '/media/gori/landing/input-validation-icon.svg',
    },
    {
      title: 'Encryption',
      description:
        "HTTPS/SSL: Encrypts data in transit between the user's browser and the web server, preventing eavesdropping and man-in-the-middle attacks. Data Encryption at Rest: Encrypts sensitive data stored in the database, such as passwords and personal information. Hashing Passwords: Store passwords using secure hashing algorithms, bcrypt, with a salt to prevent easy decryption.",
      imgSrc: '/media/gori/landing/encryption-icon.svg',
    },
    {
      title: 'Session Management',
      description:
        'Secure Cookies: Ensure cookies are flagged as Secure (transmitted over HTTPS only) and HttpOnly (not accessible via JavaScript) to prevent theft. Session Timeout: Automatically log out users after a period of inactivity to reduce the risk of session hijacking.Cross-Site Request Forgery (CSRF) Protection: Implement CSRF tokens to ensure that requests made by users are genuine and not forged by an attacker.',
      imgSrc: '/media/gori/landing/session-management-icon.svg',
    },
    {
      title: 'Error Handling and Logging',
      description:
        'Detailed Error Messages: Ensure that error messages do not reveal sensitive information that could aid an attacker. Audit Logs: Maintain detailed logs of user activity, including failed login attempts, access to sensitive data, and changes to user roles.',
      imgSrc: '/media/gori/landing/error-handling-and-logging-icon.svg',
    },
    {
      title: 'Data Protection',
      description:
        'Data Backup and Recovery: Regularly back up data and have a plan for recovering from data loss or corruption.',
      imgSrc: '/media/gori/landing/data-protection-icon.svg',
    },
    {
      title: 'Compliance and Best Practices',
      description:
        'Regular Security Audits: Perform regular code reviews to identify and address vulnerabilities Compliance with Standards: Ensure the application complies with relevant security standards and regulations, such as GDPR.',
      imgSrc: '/media/gori/landing/compliance-icon.svg',
    },
  ]

  const handleShowModal = (title: string, content: string) => {
    setModalTitle(title)
    setModalContent(content)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const checkOverflow = (element: HTMLElement) => {
    return element.scrollHeight > element.clientHeight
  }

  const descriptionRefs = useRef<(HTMLParagraphElement | null)[]>([])

  useEffect(() => {
    const updateOverflowState = () => {
      const overflowStates = descriptionRefs.current.map((ref) =>
        ref ? checkOverflow(ref) : false
      )
      setIsOverflowing(overflowStates)
    }

    updateOverflowState()

    const handleResize = () => {
      updateOverflowState()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section className='security overflow-x-hidden bg-white'>
      <div className='container'>
        <h2 className='mb-12 text-left lh-sm'>How We Protect</h2>
        <div className='row'>
          <div className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
            <div className='card'>
              <div className='card-body p-0'>
                <h3 className='card-title text-capitalize'>Your Security, Our Priority</h3>
              </div>
            </div>
          </div>

          {cardData.map((card, index) => (
            <div key={index} className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
              <div className='card'>
                <img className='mb-3' src={toAbsoluteUrl(card.imgSrc)} alt='' />
                <div className='card-body p-0'>
                  <h5 className='card-title'>{card.title}</h5>
                  <p
                    ref={(el) => (descriptionRefs.current[index] = el)}
                    className='card-text description mb-0'
                  >
                    {card.description}
                  </p>

                  {isOverflowing[index] && (
                    <button
                      className='btn btn-link text-decoration-underline pt-3 pb-0'
                      onClick={() => handleShowModal(card.title, card.description)}
                    >
                      Learn More
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className='cell col-md-4 d-flex align-items-stretch pt-9 pb-12 px-12'>
            <div className='card'>
              <div className='d-none d-md-block spacer mb-3'></div>
              <div className='card-body p-0'>
                <h5 className='card-title'>
                  <Link
                    className='text-decoration-underline'
                    style={{color: '#0f1a2a'}}
                    to='/privacy-policy'
                    target='_blank'
                  >
                    Our Privacy Policy
                    <KTSVG
                      path='/media/gori/landing/new-tab-icon.svg'
                      className='ms-4 d-inline-block h-100'
                      svgClassName='d-block h-100'
                    />
                  </Link>
                </h5>
                <p className='card-text description mb-0'>
                  Learn more about how we safeguard your data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size='lg' centered animation>
        <Modal.Header closeButton className='px-9 py-5'>
          <Modal.Title className='fs-1'>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='fs-6 fw-bold px-9 py-12' style={{color: '#64748B'}}>
          {modalContent}
        </Modal.Body>
      </Modal>
    </section>
  )
}
