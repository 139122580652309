import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import OrderService from '../../core/_requests'
import {isEmpty} from 'lodash'

type Props = {
  show: boolean
  dataModal: {
    status: 'add' | 'remove'
    title: string
    data: {
      tag: any
      selected: any
    }
  }
  handleSuccess: () => void
  handleClose: () => void
}
const WarningTagModal: React.FC<Props> = ({show, dataModal, handleSuccess, handleClose}) => {
  const intl = useIntl()
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false)
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()

  const handleSubmitForm = async () => {
    const orderIds = dataModal?.data?.selected?.map((item) => item.original.id)
    if (isEmpty(orderIds)) {
      return toast.error(intl.formatMessage({id: 'PLEASE_SELECT_ORDER'}))
    }
    if (!dataModal?.data?.tag?.id) {
      return toast.error(intl.formatMessage({id: 'PLEASE_SELECT_TAG'}))
    }
    const payload = {
      tag_id: dataModal?.data?.tag?.id,
      order_ids: orderIds,
      mode: dataModal?.status,
    }

    try {
      setIsLoadingForm(true)
      const {tags} = await OrderService.assignTag(payload, {cancelToken: newCancelToken()})
      if (tags) {
        toast.success(
          intl.formatMessage({
            id: dataModal?.status === 'add' ? 'APPLIED_SUCCESSFULLY' : 'REMOVED_SUCCESSFULLY',
          })
        )
        handleSuccess()
        handleClose()
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setIsLoadingForm(false)
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_warning_tag'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='h-auto mw-800px'
        show={show}
        backdrop='static'
        onHide={() => handleClose()}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              <div>{intl.formatMessage({id: dataModal.title})}</div>
              {/* 'DELETE_TAG' */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-center my-4'>
            <img
              className='d-block mx-auto my-5'
              src={toAbsoluteUrl('/media/gori/dashboard/error_balance.svg')}
              alt='img'
            />
            <p
              className='fs-4'
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {id: `${dataModal.status.toUpperCase()}_TAG_INPUT`},
                  {
                    name: `<strong>${dataModal?.data?.tag?.name}</strong>`,
                    selected: `<strong>${dataModal?.data?.selected?.length}</strong>`,
                  }
                ),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
                disabled={isLoadingForm}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                event={handleSubmitForm}
                loading={isLoadingForm}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {WarningTagModal}
