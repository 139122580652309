import clsx from 'clsx'
import {cloneDeep, isEmpty, isEqual, omit} from 'lodash'
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {DEFAULT_PAGE, PAGE_SIZE_MAX} from '../../../../_gori/constants'
import {KTSVG} from '../../../../_gori/helpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {Button} from '../../../../_gori/partials/widgets'
import OrderService from '../core/_requests'
import {SaveFilterModal} from '../../orders'
import {PARAMS_ORDER_FILTER} from '../core/_const'

type Props = {
  className?: string
  showModalSaveCurrentFilter: boolean
  setShowModalSaveCurrentFilter: any
  dataFilters: any
  setClickFilter: any
  handleClearAll: any
}

const SaveFilterButton: FC<Props> = ({
  showModalSaveCurrentFilter = false,
  setShowModalSaveCurrentFilter,
  dataFilters,
  className,
  setClickFilter,
  handleClearAll,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<{delete: number | undefined}>({
    delete: undefined,
  })
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [isClearValue, setIsClearValue] = useState(false)
  const filterOrderRef = useRef<any>(null)
  const [valueSearch, setValueSearch] = useState('')
  const [saveFilters, setSaveFilters] = useState<{options: any; value: any}>({
    options: [],
    value: undefined,
  })

  useEffect(() => {
    const dataParams = PARAMS_ORDER_FILTER.reduce((acc, param) => {
      const value = searchParams.get(param) || undefined
      if (value !== undefined) {
        acc[param] = value
      }
      return acc
    }, {})
    setSaveFilters((prev) => ({...prev, value: dataParams}))
  }, [searchParams])

  const getSaveFilter = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_MAX,
        },
        cancelToken: newCancelToken(),
      }

      const optionSaveFilter = await OrderService.getDataSaveFilter(config)
      const customsOption = optionSaveFilter.filters.map((filter) => ({
        label: filter.filter_name,
        value: filter.filter_payload,
        filter_id: filter.id,
      }))
      if (optionSaveFilter) {
        setSaveFilters((prev) => ({...prev, options: customsOption}))
      }
    } catch (error) {
      if (isCancel(error)) return
    }
  }, [isCancel, newCancelToken])

  useEffect(() => {
    getSaveFilter()
  }, [getSaveFilter])

  const findObjectByValue = useMemo(() => {
    return (arr, targetValue) => {
      for (const obj of arr) {
        if (isEqual(JSON.parse(obj.value), targetValue)) {
          return obj
        }
      }
      return {label: undefined, value: undefined}
    }
  }, [])

  const objectValue = useMemo(() => {
    const found = findObjectByValue(saveFilters.options, saveFilters.value)
    if (found?.label) {
      setClickFilter(true)
      if (showModalSaveCurrentFilter) {
        toast.error(
          intl.formatMessage(
            {id: 'INPUT_ALREADY_EXISTS'},
            {input: intl.formatMessage({id: 'THE_CURRENT_FILTER'})}
          )
        )
        setShowModalSaveCurrentFilter((prev) => ({...prev, save_current_filter: false}))
      }
    }
    return found
  }, [
    findObjectByValue,
    intl,
    saveFilters.options,
    saveFilters.value,
    setClickFilter,
    setShowModalSaveCurrentFilter,
    showModalSaveCurrentFilter,
  ])

  const handleDropdownClick = () => {
    setVisibleMenu(!visibleMenu)
  }

  const resetDropdown = () => {
    setVisibleMenu(false)
  }

  const customsData = useMemo(() => {
    if (!valueSearch) return saveFilters.options
    return cloneDeep(saveFilters.options).filter(({label}) => {
      return label.toLowerCase().includes(valueSearch.toLowerCase().trim())
    })
  }, [saveFilters.options, valueSearch])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterOrderRef.current && !filterOrderRef.current.contains(event.target)) {
        resetDropdown()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const deleteSavedFilter = async (filter_id) => {
    let payload = {
      filter_id: filter_id,
    }

    try {
      setLoading((prev) => ({...prev, delete: filter_id}))
      await OrderService.deleteDataSaveFilter(payload, {
        cancelToken: newCancelToken(),
      })
      await getSaveFilter()
      toast.success(intl.formatMessage({id: 'DELETE_SUCCESSFULLY'}))
    } catch (error: any) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, delete: undefined}))
    }
  }

  const handleChangeFilter = (value) => {
    let params: any = {}
    for (let param of searchParams.entries()) {
      params[param[0]] = param[1]
    }
    params = {
      ...omit(params, PARAMS_ORDER_FILTER),
      ...JSON.parse(value),
    }

    Object.keys(params).forEach((key) => {
      if (params[key] === undefined) {
        delete params[key]
      }
    })
    setSearchParams(params)
  }

  const handleDeleteField = () => {
    setIsClearValue(true)
    handleClearAll()
    setTimeout(() => {
      resetDropdown()
      setIsClearValue(false)
    }, 1000)
  }

  const renderMenuItem = (data, index) => {
    const {label, value, filter_id} = data
    return (
      <div key={index} className={clsx('dropdown-submenu', {'cursor-no-drop': loading.delete})}>
        <div
          className={clsx('dropdown-submenu__item', {
            'bg-primary text-white': isEqual(saveFilters.value, JSON.parse(value)),
            'pe-none': loading.delete,
          })}
        >
          <div
            className='flex-fill text-truncate'
            onClick={() => {
              resetDropdown()
              handleChangeFilter(value)
            }}
          >
            {label}
          </div>
          {loading.delete === filter_id ? (
            <span className='spinner-border spinner-border-sm align-middle me-2' />
          ) : (
            <Button
              event={() => deleteSavedFilter(filter_id)}
              className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1'
            >
              <KTSVG
                path='/media/gori/orders/delete.svg'
                className={clsx('opacity-50', {
                  'text-white': isEqual(saveFilters.value, JSON.parse(value)),
                })}
                svgClassName='mh-10px'
              />
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <SaveFilterModal
        optionsCurrentFilter={saveFilters.options}
        show={!objectValue?.label && showModalSaveCurrentFilter}
        nameHeader={intl.formatMessage({id: 'SAVE_FILTER'})}
        dataFilters={dataFilters}
        handleClose={() =>
          setShowModalSaveCurrentFilter((prev) => ({...prev, save_current_filter: false}))
        }
        handleUpdateFilter={getSaveFilter}
      />
      <div
        className={clsx('dropdown min-w-200px', className, {
          'border border-2 border-primary rounded-2': objectValue?.label,
        })}
        ref={filterOrderRef}
      >
        <div
          className={clsx('dropdown__label btn btn-sm btn-white btn-active-light-primary border')}
          onClick={handleDropdownClick}
        >
          <div className='dropdown__label__left fw-bold'>
            <span className='fw-bolder fs-5 text-gray-600'>
              {intl.formatMessage({id: 'SAVED_FILTERS'})}
            </span>
          </div>
          {objectValue?.label ? (
            <>
              <span className='dropdown__label__value text-truncate'>{objectValue?.label}</span>
              <Button
                event={handleDeleteField}
                className='text-hover-danger border-0 ms-2 bg-transparent rounded-circle bg-hover-white py-1 rounded-circle bg-hover-white py-1'
              >
                <KTSVG
                  path='/media/gori/orders/delete.svg'
                  className='opacity-50 m-0'
                  svgClassName='mh-10px'
                />
              </Button>
            </>
          ) : (
            <i className='bi bi-caret-down-fill ms-2'></i>
          )}
        </div>
        <div className={clsx('dropdown-menu dropdown__menu', {show: visibleMenu && !isClearValue})}>
          <div className={clsx('dropdown-submenu')}>
            <div className='px-3 py-2 sticky-top bg-white'>
              <input
                type='text'
                autoComplete='off'
                className='form-control'
                onChange={(e) => {
                  setValueSearch(e.target.value)
                }}
                value={valueSearch}
                autoFocus
              />
            </div>
            {!isEmpty(customsData) && customsData.map((item, index) => renderMenuItem(item, index))}
          </div>
        </div>
      </div>
    </>
  )
}

export {SaveFilterButton}
