import clsx from 'clsx'
import {useFormik} from 'formik'
import html2canvas from 'html2canvas'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {isFeatureEnabled, toAbsoluteUrl} from '../../../../_gori/helpers'
import {blockPopUp} from '../../../../_gori/helpers/BrowserHelpers'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import {InputPassword} from '../../../../_gori/partials/widgets/form/InputPassword'
import {Agreement} from '../../mnda'
import {OPTIONS_SIGNATURE} from '../../onboard/core/_const'
import MNDAService from '../core/_requests'
import {isEmpty} from 'lodash'
import {FEATURES} from '../../../../_gori/constants/_features'

const SalesSignature: React.FC = () => {
  const intl = useIntl()
  const passwordRef = useRef(null)
  const sigCanvas = useRef<any>(null)
  const myRef = useRef<any>(null)
  const params = useParams()
  const navigate = useNavigate()
  const {regexYup, stringYup} = UseYupValidate()
  const [validationErrors, setValidationErrors] = useState<any>()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loading, setLoading] = useState<{
    checkPassword: boolean
    hasVerified: boolean
    updateSalesSignature: boolean
  }>({
    checkPassword: false,
    hasVerified: false,
    updateSalesSignature: false,
  })
  const [errorSignature, setErrorSignature] = useState<boolean>(false)
  const [hasVerified, setHasVerified] = useState<boolean>(false)
  const [notFoundAgreement, setNotFoundAgreement] = useState<boolean>(false)

  const initialValues = {
    effective_date: null,
    company_name: null,
    client_title: null,
    client_first_name: null,
    client_last_name: null,
    client_option_signature: 'drawing',
    client_signature: null,
    password: '',
    gori_sales_title: '',
    gori_sales_first_name: '',
    gori_sales_last_name: '',
    gori_sales_signature: '',
    gori_sales_option_signature: 'drawing',
    pdf_path: null,
  }

  const mndaValidate = Yup.object().shape({
    password: stringYup(50, 'PASSWORD', !hasVerified),
    gori_sales_title: regexYup.inputText('TITLE', hasVerified),
    gori_sales_first_name: regexYup.inputText('FIRST_NAME', hasVerified),
    gori_sales_last_name: regexYup.inputText('LAST_NAME', hasVerified),
    gori_sales_signature: Yup.string()
      .when('gori_sales_option_signature', {
        is: (val) => val === 'write',
        then: regexYup.inputText('E_SIGNATURE', hasVerified),
      })
      ?.nullable(),
  })

  const handleCheckPassword = useCallback(
    async (values) => {
      setLoading((prev) => ({...prev, checkPassword: true}))
      try {
        const config = {cancelToken: newCancelToken()}
        const {status, message} = await MNDAService.verify(values?.password, config)
        if (status) {
          toast.success(message)
          setHasVerified(status)
          passwordRef.current = values.password
        }
      } catch (error: any) {
        if (isCancel(error)) return
        toast.error(error?.response?.data?.message)
        setHasVerified(error?.response?.data?.status)
      } finally {
        formik.resetForm()
        setLoading((prev) => ({...prev, checkPassword: false}))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCancel, newCancelToken]
  )

  const updateSalesSignature = useCallback(
    async (values) => {
      setLoading((prev) => ({...prev, updateSalesSignature: true}))
      let signatureDraw: any = null
      if (values.gori_sales_option_signature === 'write') {
        if (myRef.current) {
          const canvas = await html2canvas(myRef.current, {
            backgroundColor: 'transparent',
          })
          signatureDraw = canvas.toDataURL('image/png')
        }
      } else {
        signatureDraw = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      }

      const _payload = {
        gori_sales_title: values?.gori_sales_title || null,
        company_id: values?.company_id || null,
        token: passwordRef?.current || null,
        gori_sales_first_name: values?.gori_sales_first_name || null,
        gori_sales_last_name: values?.gori_sales_last_name || null,
        gori_sales_signature: signatureDraw || null,
      }
      const config = {cancelToken: newCancelToken()}

      try {
        const res = await MNDAService.salesSignature(_payload, config)
        if (res) {
          blockPopUp(res?.agreement_url)
          toast.success(intl.formatMessage({id: 'SIGNED_SUCCESSFULLY'}))
          navigate('landing')
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, updateSalesSignature: false}))
      }
    },
    [intl, isCancel, navigate, newCancelToken]
  )

  const formik = useFormik({
    initialValues,
    validationSchema: mndaValidate,
    onSubmit: async (values) => {
      if (hasVerified) {
        updateSalesSignature(values)
      } else {
        handleCheckPassword(values)
      }
    },
  })

  const getClientSignature = useCallback(async () => {
    if (!params?.id) return
    setLoading((prev) => ({...prev, hasVerified: true}))
    const config = {cancelToken: newCancelToken()}
    try {
      const res = await MNDAService.getClientSignature(params?.id, config)
      if (isEmpty(res)) {
        setNotFoundAgreement(true)
      } else {
        setNotFoundAgreement(false)
        formik.setValues({
          ...initialValues,
          ...res,
        })
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, hasVerified: false}))
    }
    return () => {
      setHasVerified(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken, params?.id])

  useEffect(() => {
    if (hasVerified) {
      getClientSignature()
    }
  }, [getClientSignature, hasVerified])

  const checkSignature = () => {
    if (
      formik.values[`gori_sales_option_signature`] === 'drawing' &&
      sigCanvas?.current?.isEmpty()
    ) {
      setErrorSignature(true)
      return true
    } else {
      setErrorSignature(false)
      return false
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/gori/login/gori_bg_rocket_1.png')})`,
        }}
      >
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <Link to={isFeatureEnabled(FEATURES.LANDING_PAGE) ? '/landing' : '/'}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/big-shipbae-logo.png')}
              className='h-60px'
            />
          </Link>
          {!hasVerified && (
            <div className='mt-12 w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
              <h1 className='text-center mb-10'>{intl.formatMessage({id: 'REVIEW_DOCUMENT'})}</h1>
              <InputPassword
                label={intl.formatMessage({id: 'PASSWORD'})}
                size='lg'
                openHighlight={false}
                formik={formik}
                name={'password'}
                required
              />
              <Button
                className='btn btn-primary w-100 mt-6'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                disabled={loading.checkPassword}
                loading={loading.checkPassword}
                event={formik.handleSubmit}
              />
            </div>
          )}
          {hasVerified && !notFoundAgreement && !loading.hasVerified && (
            <div className='w-50 fs-3'>
              <Agreement formik={formik} hasMoment={true} />
              <div className='my-10'>
                <div>
                  <div className='row my-4'>
                    <div className='col-md-3'>
                      <InputDate
                        labelClassName='fw-bolder'
                        label={intl.formatMessage({id: 'EFFECTIVE_DATE'})}
                        formik={formik}
                        name={'effective_date'}
                        required
                        className='flex-fill'
                        disabled
                      />
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                      <label className='fw-bolder form-label required'>
                        {intl.formatMessage({id: 'PRINTED_NAME'})}
                      </label>
                      <div className='d-flex'>
                        <InputTextFormik
                          className='me-3'
                          labelClassName='fw-bolder'
                          required
                          placeholder={intl.formatMessage({id: 'FIRST'})}
                          formik={formik}
                          name={'client_first_name'}
                          disabled
                        />
                        <InputTextFormik
                          className='ms-3'
                          labelClassName='fw-bolder'
                          required
                          placeholder={intl.formatMessage({id: 'LAST'})}
                          formik={formik}
                          name={'client_last_name'}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row my-4'>
                    <div className='col-md-6'>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        label={intl.formatMessage({id: 'COMPANY_NAME'})}
                        formik={formik}
                        name={'company_name'}
                        disabled
                      />
                    </div>
                    <div className='col-md-6'>
                      <InputTextFormik
                        className='flex-fill'
                        labelClassName='fw-bolder'
                        required
                        label={intl.formatMessage({id: 'TITLE'})}
                        formik={formik}
                        name={'client_title'}
                        disabled
                      />
                    </div>
                  </div>
                  <div className='row d-flex my-4'>
                    <div className='col-md-6'>
                      <div className='mb-4'>
                        <SelectFormik
                          label={intl.formatMessage({id: 'E_SIGNATURE'})}
                          labelClassName='fw-bolder'
                          required
                          emptyDefault={false}
                          options={OPTIONS_SIGNATURE}
                          name='client_option_signature'
                          formik={formik}
                          placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                          disabled
                          hasUseIntl={true}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 d-flex justify-content-center'>
                      <div className='d-flex bg-gray-200 flex-fill rounded-2 border border-secondary'>
                        <span className='signature p-2'>
                          <img
                            src={formik?.values?.client_signature || ''}
                            alt='client-signature'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className='my-6 bg-gray-700' />
                <div>
                  <div>
                    <img
                      className='h-25px'
                      src='/media/gori/hazmat/gori_company.png'
                      alt='gori-company'
                    />
                  </div>
                  <div className='d-flex my-4'>
                    <div className='col-md-6'>
                      <label className='fw-bolder form-label required'>
                        {intl.formatMessage({id: 'PRINTED_NAME'})}
                      </label>
                      <div className='d-flex'>
                        <div className='col-md-6'>
                          <InputTextFormik
                            className='me-4'
                            labelClassName='fw-bolder'
                            required
                            placeholder={intl.formatMessage({id: 'FIRST'})}
                            formik={formik}
                            name={'gori_sales_first_name'}
                            disabled={loading.updateSalesSignature || !!formik?.values?.pdf_path}
                          />
                        </div>
                        <div className='col-md-6'>
                          <InputTextFormik
                            className='me-4'
                            labelClassName='fw-bolder'
                            required
                            placeholder={intl.formatMessage({id: 'LAST'})}
                            formik={formik}
                            name={'gori_sales_last_name'}
                            disabled={loading.updateSalesSignature || !!formik?.values?.pdf_path}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 ms-4'>
                      <InputTextFormik
                        label={intl.formatMessage({id: 'TITLE'})}
                        className='me-4'
                        labelClassName='fw-bolder'
                        required
                        formik={formik}
                        name={'gori_sales_title'}
                        disabled={loading.updateSalesSignature || !!formik?.values?.pdf_path}
                      />
                    </div>
                  </div>
                  <div className='row d-flex my-4'>
                    <div className='col-md-6'>
                      <div className='mb-4'>
                        <SelectFormik
                          label={intl.formatMessage({id: 'E_SIGNATURE'})}
                          labelClassName='fw-bolder'
                          required
                          emptyDefault={false}
                          options={OPTIONS_SIGNATURE}
                          name='gori_sales_option_signature'
                          formik={formik}
                          placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                          disabled={loading.updateSalesSignature || !!formik?.values?.pdf_path}
                          hasUseIntl={true}
                        />
                      </div>
                      {formik?.values?.gori_sales_option_signature === 'drawing' ? (
                        <div className='col-md-6'>
                          <div
                            className={clsx('btn btn-secondary mt-0', {
                              'cursor-no-drop': formik?.values?.pdf_path,
                            })}
                            onClick={() => {
                              if (!formik?.values?.pdf_path) {
                                sigCanvas.current.clear()
                              }
                            }}
                          >
                            {intl.formatMessage({id: 'CLEAR'})}
                          </div>
                        </div>
                      ) : (
                        <div className='signature-text'>
                          <InputTextFormik
                            className='flex-fill'
                            labelClassName='fw-bolder'
                            required
                            formik={formik}
                            name={'gori_sales_signature'}
                            maxlength={20}
                            disabled={loading.updateSalesSignature}
                          />
                        </div>
                      )}
                    </div>
                    {formik.values?.pdf_path ? (
                      <div className='col-md-6 d-flex justify-content-center'>
                        <div className='d-flex bg-gray-200 flex-fill rounded-2 border border-secondary'>
                          <span className='signature p-2'>
                            <img
                              src={formik?.values?.gori_sales_signature || ''}
                              alt='client-signature'
                            />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {formik?.values?.gori_sales_option_signature === 'write' && (
                          <div className='col-md-6 d-flex justify-content-center'>
                            <div className='d-flex bg-white flex-fill rounded-2 border'>
                              <span className='signature p-2' ref={myRef}>
                                {formik?.values?.gori_sales_signature}
                              </span>
                            </div>
                          </div>
                        )}
                        {formik?.values?.gori_sales_option_signature === 'drawing' && (
                          <div className='col-md-6 d-flex flex-column'>
                            <div
                              className={clsx('d-flex bg-white flex-fill rounded-2 border', {
                                'border-danger': errorSignature,
                              })}
                            >
                              <SignatureCanvas
                                penColor='black'
                                canvasProps={{
                                  className: 'w-100',
                                  style: {backgroundColor: 'transparent'},
                                }}
                                ref={sigCanvas}
                                onBegin={() => {
                                  setErrorSignature(false)
                                }}
                              />
                            </div>
                            {errorSignature && (
                              <span role='alert' className='fs-6 mt-1 text-danger'>
                                {intl.formatMessage({id: 'E_SIGNATURE_IS_REQUIRED'})}
                              </span>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className='d-flex justify-content-center mt-10'>
                  <Button
                    className='btn btn-primary'
                    label={intl.formatMessage({id: 'SUBMIT'})}
                    loadingText={intl.formatMessage({id: 'SUBMIT'})}
                    disabled={loading.updateSalesSignature || !!formik?.values?.pdf_path}
                    loading={loading.updateSalesSignature}
                    event={() => {
                      if (
                        !checkSignature() ||
                        formik.values[`gori_sales_option_signature`] !== 'drawing'
                      ) {
                        formik.submitForm()
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {notFoundAgreement && (
            <div className='fw-bolder text-center my-12'>
              <h1 className='mt-8'>{intl.formatMessage({id: 'CUSTOMER_AGREEMENT_NOT_FOUND'})}</h1>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {SalesSignature}
