import axios from 'axios'
import {isEmpty} from 'lodash'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import CarrierService from '../../../app/modules/common/carriers/core/_requests'
import UserService from '../../../app/modules/users/core/_requests'
import {DEFAULT_PAGE, PAGE_SIZE_MAX} from '../../constants'
import SettingsService from '../../../app/modules/settings/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {StorageHelpers} from '../StorageHelpers'
import TableService from '../../partials/widgets/tables/core/_requests'

const SIGNATURE_ACCESS_KEY = process.env.REACT_APP_SIGNATURE_ACCESS_KEY || 'SIGNATURE_ACCESS_KEY'
const CURRENT_COMPANY_STORAGE_KEY =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE_KEY || 'CURRENT_COMPANY_STORAGE_KEY'
const CURRENT_COMPANY_STORAGE =
  process.env.REACT_APP_CURRENT_COMPANY_STORAGE || 'CURRENT_COMPANY_STORAGE'
const STATES_GLOBAL_STORAGE_KEY =
  process.env.REACT_APP_STATES_GLOBAL_STORAGE_KEY || 'STATES_GLOBAL_STORAGE_KEY'
export interface StatesGlobalContextModel {
  statesGlobal: any
  getAddress: any
  getPackages: any
  getPresets: any
  getUsers: any
  setHazmat: any
  getManageColumns: any
}

const initStatesGlobalContextPropsStates = {
  statesGlobal: StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY),
  getAddress: () => {},
  getPackages: () => {},
  getPresets: () => {},
  getUsers: () => {},
  setHazmat: () => {},
  getManageColumns: () => {},
}

const StatesGlobalContext = React.createContext<StatesGlobalContextModel>(
  initStatesGlobalContextPropsStates
)

const StatesGlobalProvider: React.FC = ({children}) => {
  const {currentCompany, currentUser} = useAuth()
  const companyLocal = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE_KEY)
  let [statesGlobal, setStateGlobal] = useState(
    StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY)
  )
  const [carriers, setCarriers] = useState<Object>(statesGlobal?.carriers)
  const [carriersPackage, setCarriersPackage] = useState<Object>(statesGlobal?.carriersPackage)
  const [address, setAddress] = useState<Object>(statesGlobal?.address)
  const [packages, setPackages] = useState<Object>(statesGlobal?.packages)
  const [presets, setPresets] = useState<Object>(statesGlobal?.presets)
  const [users, setUsers] = useState<Object>(statesGlobal?.users)
  const [manageColumns, setManageColumns] = useState<Object>(statesGlobal?.manageColumns)
  const [hazmat, setHazmat] = useState<boolean>(statesGlobal?.hazmat || currentUser?.show_reminder)
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  // begin: state global carriers
  const getCarriers = useCallback(async () => {
    try {
      const response = await CarrierService.getAll()
      if (response) {
        setCarriers(response.carriers)
        setCarriersPackage(response.package_types)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(carriers) || !isEmpty(carriersPackage)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }
    getCarriers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCarriers])
  // end: state global carriers

  // begin: state global address
  const getAddress = useCallback(async (nameAddress = 'from') => {
    try {
      const {shipping_addresses}: any = await SettingsService.getAddress({
        params: {page: DEFAULT_PAGE, page_size: PAGE_SIZE_MAX, type: nameAddress},
      })

      if (shipping_addresses) {
        setAddress((prev) => ({...prev, [nameAddress]: shipping_addresses}))
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(address)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getAddress('from')
    getAddress('to')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddress])
  // end: state global address

  // begin: state global packages
  const getPackages = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_MAX,
        },
      }
      const {packages} = await SettingsService.getPackages(config)
      if (packages) {
        setPackages(packages)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(packages)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getPackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPackages])
  // end: state global packages

  // begin: state global presets
  const getPresets = useCallback(async () => {
    try {
      const config = {
        params: {
          page: DEFAULT_PAGE,
          page_size: PAGE_SIZE_MAX,
        },
      }

      const {shipping_presets} = await SettingsService.getShippingPresets(config)
      if (shipping_presets) {
        setPresets(shipping_presets)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(presets)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getPresets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPresets])
  // end: state global presets

  // begin: state global users
  const getUsers = useCallback(async () => {
    const config = {
      params: {
        page: DEFAULT_PAGE,
        page_size: PAGE_SIZE_MAX,
        status: 'active',
        all_user: true,
      },
    }
    try {
      const response = await UserService.getListUserGlobal(config)
      if (response) {
        setUsers(response.users)
      }
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(users)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers])
  // end: state global users

  // begin: state global custom column
  const getManageColumns = useCallback(async () => {
    const config = {
      params: {
        filter_key: 'custom_columns',
      },
    }
    try {
      const {filters} = await TableService.getCustomColumns(config)
      if (filters.length > 0) {
        setManageColumns(
          filters?.reduce((allDataColumn, currentColumn) => {
            allDataColumn[currentColumn?.filter_name] = JSON.parse(currentColumn?.filter_payload)
            return allDataColumn
          }, {})
        )
      } else {
        setManageColumns({})
      }
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(manageColumns)) {
      return (axios.defaults.headers.common[`${SIGNATURE_ACCESS_KEY}`] = companyLocal)
    }

    getManageColumns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getManageColumns])
  // end: state global users

  // Update global state when making the first API call.
  useEffect(() => {
    StorageHelpers.setItemLocalStorage(STATES_GLOBAL_STORAGE_KEY, {
      carriers: carriers,
      carriersPackage: carriersPackage,
      address: address,
      packages: packages,
      presets: presets,
      users: users,
      hazmat: hazmat,
      manageColumns: manageColumns,
    })

    setStateGlobal(StorageHelpers.getItemLocalStorage(STATES_GLOBAL_STORAGE_KEY))
  }, [address, carriers, packages, presets, users, hazmat, manageColumns, carriersPackage])

  useEffect(() => {
    const company = StorageHelpers.getItemLocalStorage(CURRENT_COMPANY_STORAGE)
    StorageHelpers.setItemCookies('slug', company?.slug, {
      path: '/',
      domain: BASE_DOMAIN?.split(':')[0],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompany])

  return (
    <StatesGlobalContext.Provider
      value={{
        statesGlobal,
        getAddress,
        getPackages,
        getPresets,
        getUsers,
        setHazmat,
        getManageColumns,
      }}
    >
      {children}
    </StatesGlobalContext.Provider>
  )
}

function useStatesGlobal() {
  return useContext(StatesGlobalContext)
}

export {StatesGlobalProvider, useStatesGlobal}
