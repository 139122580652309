import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {CSSTransition} from 'react-transition-group'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {startCase} from 'lodash'
import SettingsService from '../../../core/_requests'
import {KTSVG} from '../../../../../../_gori/helpers'
import {toAbsoluteUrl} from '../../../../../../_gori/helpers'

export function Webhook() {
  const intl = useIntl()
  const [copied, setCopied] = useState(undefined)
  const [webhooks, setWebhooks] = useState<any>([])

  const onCopyText = (id: any) => {
    setCopied(id)
    setTimeout(() => {
      setCopied(undefined)
    }, 1000)
  }

  useEffect(() => {
    SettingsService.webhookUrl().then((response) => {
      setWebhooks(Object.values(response))
    })
  }, [])

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='card h-100'>
          <div className='card-header card-header-stretch'>
            <div className='card-title'>
              <h3>{intl.formatMessage({id: 'WEBHOOK_HANDLE_ENDPOINT'})}</h3>
            </div>
          </div>
          <div className='card-body'>
            {webhooks &&
              webhooks.map((webhook) => {
                return (
                  <CopyToClipboard
                    text={webhook.endpoint}
                    onCopy={() => onCopyText(webhook.endpoint)}
                  >
                    <div className='d-flex flex-row align-items-center pb-5'>
                      <div className='w-30px text-center'>
                        <img
                          alt={startCase(webhook.label)}
                          src={toAbsoluteUrl(`/media/gori/settings/${webhook.label}.png`)}
                          className={'h-15px me-3'}
                        />
                      </div>
                      <span className={'fw-bolder fs-4'}>{startCase(webhook.label)}</span>
                      <div
                        className='btn btn-active-color-primary btn-icon btn-sm btn-outline-light'
                        key={'props.value'}
                      >
                        <KTSVG path='/media/gori/account/copy.svg' className='svg-icon' />
                      </div>
                      {copied === webhook.endpoint && (
                        <div className='badge status-copy-success fw-bolder'>
                          {intl.formatMessage({id: 'COPIED'})}
                        </div>
                      )}
                    </div>
                  </CopyToClipboard>
                )
              })}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
